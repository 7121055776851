import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import { dateFormat } from "../../../../constants/Constants";
import PolicyCardStyles from "./PolicyCardStyles";
import { IPolicy } from "../../../../models/user/dashboard/Dashboard";
import { FC, useMemo, useState } from "react";
import { currencyFormat } from "../../../../utils/Utility";
import circle from "../../../../assets/svgs/circle.svg";
import { useAppDispatch } from "../../../../store/hooks";
import { setClaimsData } from "../../DashboardSlice";
import { setPolicyDetailsHeader } from "../../../../store/user/policyDetails/policyDetailsSlice";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";

interface props {
  policy: IPolicy;
  handleDownloadIdCard: (policyId: string) => void;
}

const PolicyCard: FC<props> = ({ policy, handleDownloadIdCard }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToDetails = () => {
    dispatch(setClaimsData(null));
    dispatch(setPolicyDetailsHeader(null));
    navigate(`/customer/policyDetails/${policy.policyId}`);
  };

  const dueDatePending = useMemo(() => {
    const currentDate = moment();
    const dueDate = moment(policy.dueDate);
    return dueDate.isAfter(currentDate);
  }, [policy]);

  const diffInDays = useMemo(() => {
    const dueDate = moment(policy.dueDate);
    const currentDate = moment();
    const diffDays = currentDate.diff(dueDate, "days");
    return Math.abs(diffDays);
  }, [policy]);

  let word = useMemo(() => {
    if (diffInDays > 1) {
      return "days";
    } else {
      return "day";
    }
  }, [diffInDays]);

  const getDueDateText = () => {
    if (dueDatePending) {
      return (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due : {`${moment(policy.dueDate).format(dateFormat)}`} in{" "}
          <span style={{ fontWeight: "bold" }}>
            {diffInDays} {word}
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due : {`${moment(policy.dueDate).format(dateFormat)}`} Overdue by{" "}
          <span style={{ fontWeight: "bold" }}>
            {diffInDays} {word}
          </span>
        </Typography>
      );
    }
  };

  const getIcon = (linecode: string) => {
    switch (linecode) {
      case "1":
        return <CustomIcon iconName="Car" />;
      case "2":
        return <CustomIcon iconName="Home" />; // return home;
    }
  };

  return (
    <Card sx={PolicyCardStyles.policyCard}>
      <Box sx={PolicyCardStyles.containerOne}>
        <Box sx={PolicyCardStyles.policyNumber}>
          {getIcon(policy.lineCode)}
          {/* <img src={getIcon(policy.lineCode)} alt="car-icon" /> */}
          <Box>
            <Typography sx={PolicyCardStyles.cardTopPolicyHeading}>
              Policy Number
            </Typography>
            <Typography sx={PolicyCardStyles.cardTopPolicyNumber}>
              {policy.carrierPolicyNo}
            </Typography>
          </Box>
        </Box>
        <Box sx={PolicyCardStyles.policyStatusContainer}>
          {policy.carrierPolicyStatus ? (
            <>
              <Typography sx={PolicyCardStyles.policyStatusHeading}>
                Status
              </Typography>
              <Typography sx={PolicyCardStyles.policyStatus}>
                {policy.carrierPolicyStatus}
                {policy.carrierPolicyStatus !== "Cancelled" &&
                policy?.carrierPolicyStatus !== "Cancel Notice"
                  ? ` till ${moment(policy.expiryDate).format(dateFormat)}`
                  : ""}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Typography sx={PolicyCardStyles.productName}>
          {policy.insuredName}
          {policy.insuredNameJoint ? ` | ${policy.insuredNameJoint}` : ""}
        </Typography>
      </Box>
      <Box sx={PolicyCardStyles.premium}>
        <Typography sx={PolicyCardStyles.cardBodyPremium}>
          Premium:{" "}
          <span style={{ fontWeight: "bold" }}>
            {policy.totalPremium > 0
              ? currencyFormat("us", policy.totalPremium)
              : 0}
          </span>{" "}
          | Term: <span style={{ fontWeight: "bold" }}>{policy.tenure}</span>
        </Typography>
      </Box>
      {policy.dueDate ? (
        getDueDateText()
      ) : (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due Date : No Pending Invoices
        </Typography>
      )}
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={PolicyCardStyles.dueDate}>
          <span style={PolicyCardStyles.vehicleCount}>
            {policy.riskCount}{" "}
            {policy.lineCode === "1"
              ? policy.riskCount > 1
                ? "Vehicles"
                : "Vehicle"
              : "Dwelling"}
          </span>
        </Typography>
        {policy.payPlanType === "Automatic" ? (
          <Box sx={PolicyCardStyles.autoDebitActive}>
            <CheckCircleOutlineIcon sx={PolicyCardStyles.checkCircleIcon} />
            <Typography sx={PolicyCardStyles.cardBodyPremiumAuto}>
              Auto Debit Active
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={PolicyCardStyles.actions}>
        <Button
          variant="contained"
          sx={PolicyCardStyles.viewPolicy}
          onClick={redirectToDetails}
          disabled={policy.lineCode === "2"}
        >
          View Policy
        </Button>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img src={circle} alt="dots" />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => handleDownloadIdCard(policy.policyId)}
            disabled
          >
            Download ID
          </MenuItem>
        </Menu>
      </Box>
    </Card>
  );
};

export default PolicyCard;
