export const BillingActivityCardStyles = {
  activityCard: {
    width: "100%",
    position: "relative",
    padding: "20px",
    "@media (min-width:360px) and (max-width:1024px)": {
      border: "1px solid rgba(201, 225, 236, 1)",
      background: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
    },
    "@media (min-width: 1025px)": {
      "&:hover": {
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.09) inset",
        background: "rgba(243, 245, 246, 1)",
      },
    },
    "@media (max-width: 768px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  activeCardContainer: {
    display: "grid",
    gap: "20px",
    gridTemplateColumns: "0.5fr 1fr 1fr 1fr 1fr",
    justifyContent: "space-between",
  },
  icon: {
    img: {
      width: "40.94px",
      height: "40px",
      "@media (min-width: 360px) and (max-width: 1024px)": {
        width: "46.36px",
        height: "48.88px",
        gridArea: "icon",
      },
    },
  },
  heading: {
    width: "auto",
    fontSize: "20px",
    fontWeight: "400",
    textAlign: "left",
    fontFamily: "Noto Sans",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: "400",
      letterSpacing: "0em",
    },
  },
  description: {
    fontWeight: "300",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: "300",
      letterSpacing: "0em",
    },
  },

  date: {
    fontWeight: "300",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: "300",
      letterSpacing: "0em",
    },
  },

  closureReason: {
    width: "auto",
    height: "20px",
    marginTop: "12.5px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      width: "222px",
      height: "18px",
      fontSize: "16px",
      fontWeight: "300",
      letterSpacing: "0em",
      textAlign: "left",
      marginTop: "5px",
      marginBottom: "20px",
    },
  },
};
