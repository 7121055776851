import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { JsonEditor } from "json-edit-react";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  deleteForm,
  getForms,
  getFormTypes,
  addForm,
} from "../../../store/admin/forms/formsActions";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { ILookupValue } from "../../../models/common/common";
import CustomTable, { IColumn } from "../../../common/CustomTable/CustomTable";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import { IAddFormRequestBody, IForm } from "../../../models/admin/Forms/Forms";
import CloseIcon from "@mui/icons-material/Close";

interface IOption {
  id: string;
  name: string;
  code: string;
}

interface IConditionOption {
  source: string;
  value: string;
  attribute: "disabled" | "hidden";
}

interface ICondition {
  type: "and" | "or";
  conditionOptions: IConditionOption[];
}

interface Field {
  name: string;
  value: string;
  label: string;
  type: string;
  required: boolean;
  disabled: boolean;
  placeholder?: string;
  hidden?: boolean;
  condition?: ICondition;
  asyncType?: string;
  asyncOptionsFn?: string;
  options?: IOption[];
  asyncValueFn?: string;
}

interface Section {
  layoutColumns: number;
  fields: Field[];
  sectionHeading?: string;
}

interface IFormState {
  formHeading?: string;
  sections?: Section[];
}

const Forms = () => {
  const dispatch = useAppDispatch();

  const { formTypeOptions, forms } = useAppSelector((state) => state.forms);
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state) => state.common
  );

  const [addFormRequestBody, setAddFormRequestBody] =
    useState<IAddFormRequestBody>({
      createdBy: "Admin",
      formCode: "",
      formContent: "",
      formName: "",
      updatedBy: "Admin",
    });
  const [json, setJson] = useState<IFormState>({});
  const [isAddForm, setIsAddForm] = useState<boolean>(true);
  const [deleteFormCode, setDeleteFormCode] = useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
  const [editFormDialogOpen, setEditFormDialogOpen] = useState<boolean>(false);
  const [deleteFormAlertDialogOpen, setDeleteFormAlertDialogOpen] =
    useState<boolean>(false);

  const handleFormTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const selectedFormType = formTypeOptions.find(
        (option: ILookupValue) => option.code === event.target.value
      );
      if (selectedFormType) {
        setAddFormRequestBody((prevState) => ({
          ...prevState,
          formCode: selectedFormType.code,
          formName: selectedFormType.label,
        }));
        setJson({});
      }
    },

    [formTypeOptions]
  );

  const handleJsonChange = useCallback((data: any) => {
    setJson(data);
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      dispatch(
        addForm({
          ...addFormRequestBody,
          formContent: JSON.stringify(json),
        })
      );
    },
    [dispatch, addFormRequestBody, json]
  );

  const handleCancelClick = useCallback(() => {
    setAddFormRequestBody((prevState) => ({
      ...prevState,
      formCode: "",
      formName: "",
    }));
  }, []);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    if (
      isSuccess &&
      (calledReducerType === "forms/addForm" ||
        calledReducerType === "forms/deleteForm")
    ) {
      dispatch(getForms());
    }
  }, [isSuccess, calledReducerType, dispatch]);

  const handleEditIconClick = useCallback((form: IForm) => {
    setIsAddForm(false);
    setAddFormRequestBody(form);
    setJson(JSON.parse(form.formContent));
    setEditFormDialogOpen(true);
  }, []);

  const handleEditFormCancelClick = useCallback(() => {
    setEditFormDialogOpen(false);
    setIsAddForm(true);
    setAddFormRequestBody((prevState) => ({
      ...prevState,
      formCode: "",
      formName: "",
    }));
  }, []);

  const handleDeleteIconClick = useCallback((formCode: string) => {
    setDeleteFormCode(formCode);
    setDeleteFormAlertDialogOpen(true);
  }, []);

  const handleAlertDialogCancelClick = () => {
    setDeleteFormCode("");
    setDeleteFormAlertDialogOpen(false);
  };

  const handleDeleteForm = useCallback(() => {
    dispatch(deleteForm(deleteFormCode));
  }, [dispatch, deleteFormCode]);

  const columns: IColumn[] = [
    { label: "Form", accessorKey: "formName" },
    { label: "Code", accessorKey: "formCode" },
    {
      label: "Actions",
      styles: { textAlign: "center" },
      Cell: (row: any) => (
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={() => handleEditIconClick(row)}>
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleDeleteIconClick(row.formCode)}>
            <Tooltip title="Delete">
              <DeleteOutlineOutlined />
            </Tooltip>
          </IconButton>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getFormTypes());
    dispatch(getForms());
  }, [dispatch]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      (calledReducerType === "forms/addForm" ||
        calledReducerType === "forms/deleteForm")
    ) {
      setMessageDialogOpen(true);
      setAddFormRequestBody((prevState) => ({
        ...prevState,
        formCode: "",
        formName: "",
        formContent: "",
        effectiveDate: "",
        expirationDate: "",
      }));
      setIsAddForm(true);
      setEditFormDialogOpen(false);
    }
  }, [isSuccess, isError, calledReducerType]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Card>
          <CardContent>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Typography>Add Form</Typography>
              <FormControl fullWidth>
                <InputLabel>Forms</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={isAddForm ? addFormRequestBody.formCode : ""}
                  label="Forms"
                  onChange={handleFormTypeChange}
                >
                  {formTypeOptions
                    .filter(
                      (option) =>
                        !forms.some((form) => form.formCode === option.code)
                    )
                    .map((option: ILookupValue) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {isAddForm && addFormRequestBody.formCode ? (
                <>
                  <JsonEditor data={json} setData={handleJsonChange} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ width: "max-content" }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleCancelClick}
                      sx={{ width: "max-content" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </form>
          </CardContent>
        </Card>
        <CustomTable columns={columns} data={forms} />
      </Box>
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
      <AlertDialog
        open={deleteFormAlertDialogOpen}
        handleCancelClick={handleAlertDialogCancelClick}
        handleOkClick={handleDeleteForm}
        title="Deleting this form will effect the end user, is it ok?"
      />
      <Dialog open={editFormDialogOpen} fullScreen sx={{ height: "100vh" }}>
        <form onSubmit={handleSubmit}>
          <AppBar sx={{ position: "fixed" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleEditFormCancelClick}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {addFormRequestBody.formName}
              </Typography>
              <Button autoFocus color="inherit" type="submit">
                save
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ marginTop: "65px" }}>
            <JsonEditor
              data={json}
              setData={handleJsonChange}
              minWidth={"100%"}
              className="jsonEdior"
            />
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default Forms;
