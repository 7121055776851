import { Box, Typography } from "@mui/material";
import horizontalArrow from "../../../../../assets/horizontalArrow.svg";
import CoveragesLogo from "../../../../../assets/CoveragesLogo.svg";
import DiscountsLogo from "../../../../../assets/DiscountsLogo.svg";
import React, { useState } from "react";
import downArrow from "../../../../../assets/downArrow.svg";
import { IPremiumPerVehicle } from "../../../../../models/user/policyDetails/PolicyDetails";
import { CoveragesStyles } from "../CoveragesStyles";
import Discounts from "../Discounts/Discounts";
import ResponsiveCoveragesStyles from "./ResponsiveCoveragesStyles";

interface IProps {
  perVehicledata: IPremiumPerVehicle[];
  totalPremium: string | null;
  data: any[];
}

const ResponsiveCoverages: React.FC<IProps> = ({
  perVehicledata,
  totalPremium,
  data,
}) => {
  const [expandedItems, setExpandedItems] = useState<(boolean | undefined)[]>(
    []
  );

  const handleClick = (index: number) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = [...prevExpandedItems];
      newExpandedItems[index] = !newExpandedItems[index];
      for (let i = 0; i < newExpandedItems.length; i++) {
        if (i !== index) {
          newExpandedItems[i] = false;
        }
      }
      return newExpandedItems;
    });
  };

  const getPerVehicleSection = () => {
    let element: any = <Box>No records found</Box>;
    if (perVehicledata && perVehicledata.length > 0) {
      element = perVehicledata.map((item, index) => {
        return (
          <Box
            key={`p-v-${index}`}
            sx={CoveragesStyles.vechilePremiumTextValues}
          >
            <Typography sx={CoveragesStyles.vechileValueText}>
              ${item.amount}
            </Typography>
            <Typography sx={CoveragesStyles.vechileNameText}>
              {`${item.manufactureYr}-${item.make}-${item.model}`}
            </Typography>
          </Box>
        );
      });
    }
    return element;
  };

  const getLimit = (dataString: string) => {
    let defaultVal = "N/A";
    const limitArray = dataString.split("/");
    if (limitArray[1]) {
      defaultVal = `$${limitArray[0]} / $${limitArray[1]}`;
    } else {
      defaultVal = /\d/.test(limitArray[0]) ? `$${limitArray[0]}` : dataString;
    }

    return defaultVal;
  };

  const getCoveragesSection = () => {
    let element: any = <Box>No Coverage found</Box>;
    if (data && data.length > 0) {
      element = data.map((coverageItem, index) => {
        return (
          <Box
            key={`mob-coverage-list${index}`}
            sx={{
              ...CoveragesStyles.coveragesCard,
              backgroundColor: expandedItems[index] ? "#F5FAFC" : "#FFFFFF",
              width: expandedItems[index] ? "auto" : "auto",
              marginLeft: expandedItems[index] ? "-10px" : "0px",
              marginRight: expandedItems[index] ? "-10px" : "0px",
              boxShadow: expandedItems[index]
                ? "0px 2px 4px 0px #00000017 inset"
                : "none",
              padding: expandedItems[index]
                ? "22px 15px 20px 15px"
                : "15px 5px 20px 5px",
              borderRadius:
                index === expandedItems.length - 1
                  ? "0px 0px 10px 10px"
                  : "0px",
            }}
          >
            <Box sx={CoveragesStyles.coveragesHead} key={`c-mob-${index}`}>
              <Box>
                <Typography sx={CoveragesStyles.coveragesTextHeading}>
                  {coverageItem.coverageName}
                </Typography>
                <Typography sx={CoveragesStyles.perItemText}>
                  {coverageItem.coverageSubText
                    ? `(${coverageItem.coverageSubText})`
                    : ""}
                </Typography>
              </Box>
              <img
                onClick={() => handleClick(index)}
                src={expandedItems[index] ? downArrow : horizontalArrow}
                alt={expandedItems[index] ? "Down-arrow" : "Horizontal-arrow"}
              />
            </Box>
            <Box sx={CoveragesStyles.limits}>
              <Typography sx={CoveragesStyles.limitsText}>Limit</Typography>
              <Typography sx={CoveragesStyles.limitsCount}>
                {getLimit(coverageItem.limit)}
              </Typography>
            </Box>
            {expandedItems[index] && (
              <Box sx={CoveragesStyles.expandedItemsContainer}>
                <Box sx={CoveragesStyles.expandedItems}>
                  <Typography sx={CoveragesStyles.expanededItemkeyText}>
                    Deductible
                  </Typography>
                  <Typography sx={CoveragesStyles.expanededItemvalueText}>
                    {coverageItem.deductible
                      ? `$${coverageItem.deductible}`
                      : "N/A"}
                  </Typography>
                </Box>
              </Box>
            )}
            {expandedItems[index] &&
              Object.keys(data[0])
                .filter(
                  (fItem) =>
                    fItem !== "coverageName" &&
                    fItem !== "coverageSubText" &&
                    fItem !== "deductible" &&
                    fItem !== "limit"
                )
                .map((cItem, cIndex) => {
                  return (
                    <Box
                      sx={CoveragesStyles.expandedItems}
                      key={`mob-dynamic-${cIndex}`}
                    >
                      <Typography sx={CoveragesStyles.expanededItemkeyText}>
                        {cItem}
                      </Typography>
                      <Typography sx={CoveragesStyles.expanededItemvalueText}>
                        {coverageItem[cItem]
                          ? `$${coverageItem[cItem]}`
                          : "N/A"}
                      </Typography>
                    </Box>
                  );
                })}
          </Box>
        );
      });
    }
    return element;
  };

  return (
    <Box sx={CoveragesStyles.totalContainer}>
      <Box sx={CoveragesStyles.responsiveContainer}>
        <Box sx={CoveragesStyles.cardpremiums}>
          <Typography sx={CoveragesStyles.premiumText}>
            Total Policy Premium
          </Typography>
          <Typography sx={CoveragesStyles.premiumValueText}>
            ${totalPremium}
          </Typography>
        </Box>
        <Box sx={CoveragesStyles.cardTotalPremiums}>
          <Typography sx={CoveragesStyles.premiumText}>
            Total Premium Per Vehicle
          </Typography>
          <Box sx={CoveragesStyles.vechilePremiumValues}>
            {getPerVehicleSection()}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box sx={ResponsiveCoveragesStyles.coverageAndLimitsHeading}>
          <Box>
            <img src={CoveragesLogo} alt="coverages logo" />
          </Box>
          <Box>
            <Typography
              sx={ResponsiveCoveragesStyles.coverageAndLimitsHeadingText}
            >
              Coverages and limits
            </Typography>
          </Box>
        </Box>
        <Box sx={CoveragesStyles.coverages}>
          <Typography sx={CoveragesStyles.headText}>Coverages</Typography>
          <Box>{getCoveragesSection()}</Box>
        </Box>
      </Box>
      <Box sx={CoveragesStyles.mobileViewDiscounts}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <img src={DiscountsLogo} alt="discounts logo" />
          <Typography sx={CoveragesStyles.discountsText}>Discounts</Typography>
        </Box>
        <Discounts />
      </Box>
    </Box>
  );
};
export default ResponsiveCoverages;
