import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import LoginStyles from "./LoginStyles";
import { resetCommonSlice } from "../store/common/CommonSlice";
import LoginWithNameAndMobile from "./LoginWithNameAndMobile/LoginWithNameAndMobile";
import LoginWithEmailOrMobile from "./LoginWithEmailOrMobile/LoginWithEmailOrMobile";
import { getUiSettings } from "../store/admin/uiSettings/uiSettingsActions";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const { uiSettings } = useAppSelector((state) => state.uiSettings);

  const [loginType, setLoginType] = useState<string>("direct");

  const handleLoginWithChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginType((event.target as HTMLInputElement).value);
      dispatch(resetCommonSlice());
    },
    [dispatch]
  );

  const getLoginForm = useCallback(() => {
    switch (loginType) {
      case "direct":
        return <LoginWithNameAndMobile loginType={loginType} />;
      case "emailOrMobile":
        return <LoginWithEmailOrMobile loginType={loginType} />;
    }
  }, [loginType]);

  useEffect(() => {
    dispatch(getUiSettings());
  }, [dispatch]);

  return (
    <Box sx={LoginStyles.login}>
      <Box sx={LoginStyles.loginImage}>
        {uiSettings && uiSettings?.loginBanner && (
          <img
            src={`data:image/png;base64,${uiSettings.loginBanner}`}
            alt="Logo Preview"
          />
        )}
      </Box>
      <Box style={LoginStyles.loginCardContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "80%",
          }}
        >
          <Box sx={LoginStyles.logo}>
            {uiSettings && uiSettings?.logo && (
              <img
                src={`data:image/png;base64,${uiSettings.logo}`}
                alt="Logo Preview"
              />
            )}
          </Box>
          <Typography sx={LoginStyles.loginMessage}>
            Welcome, please login to continue
          </Typography>
          <FormControl>
            <FormLabel
              sx={{
                fontFamily: "Playfair Display",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "22px",
                color: "rgba(56, 61, 78, 1)",
              }}
            >
              Login With
            </FormLabel>
            <RadioGroup
              defaultValue="emailOrMobile"
              value={loginType}
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={handleLoginWithChange}
            >
              <FormControlLabel
                value="direct"
                control={<Radio />}
                label="Name & Mobile"
              />
              <FormControlLabel
                value="emailOrMobile"
                control={<Radio />}
                label="Email / Mobile"
              />
              <FormControlLabel
                value="google"
                control={<Radio />}
                label="Google"
                disabled
              />
            </RadioGroup>
          </FormControl>
          {getLoginForm()}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
