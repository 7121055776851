import React, {
  useState,
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
} from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  Grid,
  InputLabel,
  SelectChangeEvent,
  FormLabel,
  OutlinedInput,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  IField,
  IDynamicFormFieldsJson,
  IFormValues,
  ISection,
  IOption,
  IAsyncOptionFn,
  IAsyncValueFn,
  IAsyncLabelFn,
} from "../../models/user/DynamicForm/DynamicForm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

interface IProps {
  formFieldsJson: IDynamicFormFieldsJson;
  asyncOptionsFunctions?: { [key: string]: IAsyncOptionFn };
  asyncValueFunctions?: { [key: string]: IAsyncValueFn };
  asyncLabelFunctions?: { [key: string]: IAsyncLabelFn };
  onFormSubmit: (formValues: IFormValues) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const DynamicForm: React.FC<IProps> = ({
  formFieldsJson,
  asyncOptionsFunctions,
  asyncValueFunctions,
  asyncLabelFunctions,
  onFormSubmit,
}) => {
  const [formSchema, setFormSchema] = useState<IDynamicFormFieldsJson>({});

  const handleInputChange = useCallback(
    async (
      name: string,
      value: any,
      type?: string,
      disableFuture?: boolean
    ) => {
      const newSections: ISection[] = await Promise.all(
        (formSchema.sections || []).map(async (section) => {
          const updatedFields: IField[] = await Promise.all(
            section.fields.map(async (field) => {
              let newField = { ...field };
              if (type === "date" && newField.type === "time") {
                newField.value = "";
                newField.error = "";
                if (value && value.isBefore(dayjs(), "day")) {
                  newField.disableFuture = false;
                } else {
                  newField.disableFuture = true;
                }
              }
              if (newField.name === name) {
                if (
                  type === "date" &&
                  disableFuture &&
                  value &&
                  value.isAfter(dayjs())
                ) {
                  newField.error = "Future dates are not allowed";
                } else if (type === "time" && disableFuture && value) {
                  const selectedDateField = formSchema.sections
                    ?.flatMap((section) => section.fields)
                    .find((f) => f.type === "date" && f.value);

                  const selectedDate = selectedDateField
                    ? dayjs(selectedDateField.value)
                    : null;

                  const today = dayjs();

                  // If today is selected, check if the time is in the future
                  if (selectedDate && selectedDate.isSame(today, "day")) {
                    const currentTime = today.hour() * 60 + today.minute();
                    const selectedTime = value.hour() * 60 + value.minute();
                    if (selectedTime > currentTime) {
                      newField.error =
                        "Future time is not allowed for today's date";
                    } else {
                      newField.error = "";
                      newField.value = value;
                    }
                  } else {
                    newField.error = "";
                    newField.value = value;
                  }
                } else {
                  if (newField.length && value.length > newField.length) {
                    newField.error = `Maximum length of ${newField.length} characters exceeded`;
                  } else {
                    newField.value = value;
                    newField.error = "";
                  }
                }
              }

              if (
                newField.asyncType === "asyncValue" &&
                newField.asyncEventType === "onChange" &&
                newField.asyncValueFn &&
                newField.asyncSourceName === name
                // &&
                // newField.asyncSourceValues?.some(
                //   (asyncSourceValue) => asyncSourceValue === value
                // )
              ) {
                newField.value = await newField.asyncValueFn(value);
              }
              if (
                newField.asyncType === "asyncOptions" &&
                newField.asyncEventType === "onChange" &&
                newField.asyncOptionsFn &&
                newField.asyncSourceName === name
                // &&
                // newField.asyncSourceValues?.some(
                //   (asyncSourceValue) => asyncSourceValue === value
                // )
              ) {
                newField.options = await newField.asyncOptionsFn(value);
              }
              let flags: string[] = [];
              if (
                newField.condition &&
                newField.condition.conditionOptions &&
                newField.condition.conditionOptions.length > 0
              ) {
                newField.condition.conditionOptions.forEach(
                  (conditionOption) => {
                    if (conditionOption.source === name) {
                      if (conditionOption.value === value) {
                        flags.push("true");
                      } else {
                        flags.push("false");
                      }
                      if (
                        newField.condition &&
                        newField.condition.type === "and"
                      ) {
                        newField.disabled =
                          flags.includes("true") &&
                          conditionOption.attribute === "disabled";
                        newField.hidden =
                          flags.includes("true") &&
                          conditionOption.attribute === "hidden";
                        newField.required = !flags.includes("true");
                      }
                    }
                  }
                );
              }
              return newField;
            })
          );
          return {
            ...section,
            fields: updatedFields,
          };
        })
      );
      setFormSchema((prevState) => ({
        ...prevState,
        sections: newSections,
      }));
    },
    [formSchema]
  );

  const renderInputField = useCallback(
    (field: IField): JSX.Element | null => {
      const fieldComponent: { [key: string]: () => JSX.Element } = {
        text: () => (
          <>
            <InputLabel>{`${field.label}${
              field.required ? "*" : ""
            }`}</InputLabel>
            <OutlinedInput
              type="text"
              label={field.label}
              value={field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(field.name, e.target.value)
              }
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          </>
        ),
        textarea: () => (
          <>
            <InputLabel>{`${field.label}${
              field.required ? "*" : ""
            }`}</InputLabel>
            <OutlinedInput
              label={field.label}
              value={field.value}
              multiline
              maxRows={field.maxRows}
              rows={field.rows}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(field.name, e.target.value)
              }
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          </>
        ),
        number: () => (
          <>
            <InputLabel>{`${field.label}${
              field.required ? "*" : ""
            }`}</InputLabel>
            <OutlinedInput
              type="number"
              label={field.label}
              value={field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(field.name, parseFloat(e.target.value))
              }
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          </>
        ),
        checkbox: () => (
          <FormControlLabel
            label={`${field.label}${field.required ? "*" : ""}`}
            control={
              <Checkbox
                checked={Boolean(field.value)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(field.name, e.target.checked)
                }
              />
            }
          />
        ),
        radio: () => (
          <>
            <FormLabel>{`${field.label}${
              field.required ? "*" : ""
            }`}</FormLabel>
            <RadioGroup
              row
              value={field.value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(field.name, e.target.value)
              }
            >
              {field.options?.map((option: IOption) => (
                <FormControlLabel
                  key={option.code}
                  value={option.code}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          </>
        ),
        select: () => (
          <>
            <InputLabel>{`${field.label}${
              field.required ? "*" : ""
            }`}</InputLabel>
            <Select
              value={field.value}
              onChange={(e: SelectChangeEvent) =>
                handleInputChange(field.name, e.target.value as string)
              }
              label={field.label}
              placeholder={field.placeholder}
              disabled={field.disabled}
            >
              {field.options?.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
        ),
        date: () => (
          <DatePicker
            label={`${field.label}${field.required ? "*" : ""}`}
            value={field.value ? field.value : null}
            onChange={(date) =>
              handleInputChange(
                field.name,
                date,
                field.type,
                field.disableFuture
              )
            }
            disabled={field.disabled}
            disableFuture={field.disableFuture}
            disablePast={field.disablePast}
          />
        ),
        time: () => (
          <TimePicker
            label={`${field.label}${field.required ? "*" : ""}`}
            value={field.value ? field.value : null}
            onChange={(time) =>
              handleInputChange(
                field.name,
                time,
                field.type,
                field.disableFuture
              )
            }
            disabled={field.disabled}
            disableFuture={field.disableFuture}
            disablePast={field.disablePast}
          />
        ),
        file: () => (
          <Box>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              {field.label}
              <VisuallyHiddenInput type="file" />
            </Button>
            {/* <TextField
              type="file"
              label={`${field.label}${field.required ? "*" : ""}`}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange(field.name, e.target.files?.[0])
              }
              InputLabelProps={{ shrink: true }}
              disabled={field.disabled}
            /> */}
          </Box>
        ),
      };

      if (!fieldComponent[field.type]) {
        console.error(`Unsupported field type: ${field.type}`);
        return null;
      }

      return (
        <FormControl fullWidth margin="normal" component="fieldset">
          {fieldComponent[field.type]()}
          {field.error ? (
            <Typography sx={{ fontSize: "1rem", color: "red" }}>
              {field.error}
            </Typography>
          ) : (
            <></>
          )}
        </FormControl>
      );
    },
    [handleInputChange]
  );

  const renderSection = useCallback(
    (section: ISection, index: number) => (
      <Box key={`section-${index}`}>
        {section.sectionHeading && (
          <Typography variant="h5">{section.sectionHeading}</Typography>
        )}
        <Grid container spacing={2}>
          {section.fields
            .filter((field) => !field.hidden)
            .map((field) => (
              <Grid
                item
                xs={12}
                sm={12 / section.layoutColumns}
                key={field.name}
              >
                {renderInputField(field)}
              </Grid>
            ))}
        </Grid>
      </Box>
    ),
    [renderInputField]
  );
  const renderForm = useCallback(
    () =>
      formSchema.sections ? (
        <>
          {formSchema.sections.map((section, index) =>
            renderSection(section, index)
          )}
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </>
      ) : (
        <></>
      ),
    [formSchema, renderSection]
  );

  const validateFormValues = useCallback((): boolean => {
    let hasErrors = false;
    setFormSchema((prevState) => {
      const newState = { ...prevState };
      if (newState.sections) {
        newState.sections.forEach((section) =>
          section.fields.forEach((field) => {
            if ((!field.value && field.required) || field.error) {
              field.error = field.error
                ? field.error
                : `${field.label} is required`;
              hasErrors = true;
            } else {
              field.error = "";
            }
          })
        );
      }
      return newState;
    });
    return !hasErrors;
  }, []);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (validateFormValues()) {
      const formValues: { [key: string]: any } = {};
      formSchema.sections?.forEach((section) =>
        section.fields.forEach(
          (field) => (formValues[field.name] = field.value)
        )
      );
      onFormSubmit(formValues);
    }
  };

  useEffect(() => {
    const fetchAsyncFields = async () => {
      const formSchema: IDynamicFormFieldsJson = JSON.parse(
        JSON.stringify(formFieldsJson)
      );
      const promises: Promise<void>[] =
        formSchema.sections?.flatMap((section) =>
          section.fields.map(async (field) => {
            if (field.asyncType === "asyncOptions" && asyncOptionsFunctions) {
              field.asyncOptionsFn =
                asyncOptionsFunctions[
                  field.asyncOptionsFn as keyof typeof field.asyncOptionsFn
                ];
              if (field.asyncOptionsFn && field.asyncEventType === "onLoad") {
                field.options = await field.asyncOptionsFn(field.name);
              }
            }
            if (field.asyncType === "asyncValue" && asyncValueFunctions) {
              field.asyncValueFn =
                asyncValueFunctions[
                  field.asyncValueFn as keyof typeof field.asyncValueFn
                ];
              if (field.asyncEventType === "onLoad") {
                field.value = await field.asyncValueFn();
              }
            }
            if (field.asyncType === "asyncLabel" && asyncLabelFunctions) {
              field.asyncLabelFn =
                asyncLabelFunctions[
                  field.asyncLabelFn as keyof typeof field.asyncLabelFn
                ];
              if (field.asyncEventType === "onLoad") {
                field.label = `${field.label} ${await field.asyncLabelFn()}`;
              }
            }
          })
        ) || [];

      await Promise.all(promises);

      setFormSchema(formSchema);
    };

    fetchAsyncFields();
  }, [
    formFieldsJson,
    asyncOptionsFunctions,
    asyncValueFunctions,
    asyncLabelFunctions,
  ]);

  return (
    <form onSubmit={handleFormSubmit}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {formSchema.formHeading ? (
          <Typography variant="h4" mb={4}>
            {formSchema.formHeading}
          </Typography>
        ) : (
          <></>
        )}
        {renderForm()}
      </LocalizationProvider>
    </form>
  );
};

export default DynamicForm;
