import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import MicIcon from "@mui/icons-material/Mic";
import ReactMarkdown from "react-markdown";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  startAudioRecording,
  stopAudioRecording,
  cancelAudioRecording,
} from "./AudioRecording";
import { IMessage } from "../../../models/user/InsureAssist/InsureAssist";
import InsureAssistStyles from "./InsureAssistStyles";
import axios from "axios";
import { clearChat } from "../../../store/user/insureAssist/insureAssistActions";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const InsureAssist: React.FC<Props> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const wsUrl = process.env.REACT_APP_CHAT_WEB_SOCKET_URL;
  const transcriptUrl = process.env.REACT_APP_CHAT_TRANSCRIPT_URL;

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [transcribeLoading, setTranscribeLoading] = useState<boolean>(false);
  const [transcriError, setTranscribeError] = useState<boolean>(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [unsupportedBrowser, setUnsupportedBrowser] = useState<boolean>(false);
  const [elapsedTime, setElapsedTime] = useState<string>("00:00");

  const newMessageRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery("(max-width:992px)");

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim() !== "" && socket) {
      const messageObj: IMessage = {
        content: message,
        creationTime: new Date().toISOString(),
        level: messages.length,
        sender: "customer",
        type: "text",
      };
      socket.send(JSON.stringify(messageObj));
      setMessage("");
      setMessages((prevState) => [...prevState, messageObj]);
      setLoading(true);
    }
  };

  const handleStartRecording = () => {
    startAudioRecording(
      (value) => setIsRecording(value),
      (value) => setUnsupportedBrowser(value),
      (value) => setElapsedTime(value)
    );
  };

  const transcribeAudio = async (audioMessage: IMessage) => {
    if (transcriptUrl) {
      try {
        setTranscribeLoading(true);
        setTranscribeError(false);
        const response = await axios.post(transcriptUrl, audioMessage, {
          withCredentials: true,
        });
        if (response.status !== 200) {
          throw new Error("Failed to transcribe audio");
        }
        const data = response.data;
        setTranscribeLoading(false);
        return data;
      } catch (error) {
        setTranscribeLoading(false);
        setTranscribeError(true);
        console.error("Error transcribing audio:", error);
        return null;
      }
    }
  };

  const handleStopRecording = () => {
    stopAudioRecording(async (blob) => {
      if (blob && socket) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64data = reader.result;
          if (typeof base64data === "string") {
            let audioMessage: IMessage = {
              content: base64data,
              creationTime: new Date().toISOString(),
              level: messages.length,
              sender: "customer",
              type: "audio",
            };
            setMessages((prevState) => [...prevState, audioMessage]);
            setLoading(true);
            socket.send(JSON.stringify(audioMessage));
            audioMessage.transcription = await transcribeAudio(audioMessage);
          } else {
            console.error("Failed to read audio blob as a string.");
          }
        };
      }
      setIsRecording(false);
    });
    cancelAudioRecording();
  };

  const handleClose = useCallback(
    (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason === "backdropClick" || !open) {
        return;
      }
      if (userDetails && userDetails.customerId) {
        setAlertDialogOpen(false);
        setMessages([]);
        setMessage("");
        setOpen((prevState: boolean) => !prevState);
        setLoading(false);
        setSocket(null);
        setUnsupportedBrowser(false);
        setElapsedTime("00:00");
        dispatch(clearChat(userDetails.customerId));
      }
    },
    [userDetails, dispatch, setOpen, open]
  );

  const handleClearChat = useCallback(() => {
    if (userDetails && userDetails.customerId) {
      dispatch(clearChat(userDetails.customerId));
      setMessages([
        {
          content: `Hi ${userDetails?.customerName}, How may I assist you today?`,
          creationTime: new Date().toISOString(),
          level: 1,
          sender: "system",
          type: "text",
        },
      ]);
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (open && wsUrl) {
      const ws = new WebSocket(
        `${wsUrl}?customerId=${userDetails?.customerId}`
      );

      ws.onopen = () => {
        setMessages([
          {
            content: `Hi ${userDetails?.customerName}, How may I assist you today?`,
            creationTime: new Date().toISOString(),
            level: 1,
            sender: "system",
            type: "text",
          },
        ]);
      };

      // ws.onclose = () => {
      //     handleClose();
      // };

      ws.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, message]);
        setLoading(false);
      };

      ws.onerror = (error) => {
        handleClose();
        console.error("WebSocket error:", error);
      };

      setSocket(ws);

      return () => {
        ws.close();
      };
    }
  }, [open, wsUrl, userDetails, handleClose]);

  useEffect(() => {
    if (newMessageRef.current) {
      newMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [messages]);

  useEffect(() => {
    if (unsupportedBrowser) {
      if (userDetails && userDetails.customerId) {
        dispatch(clearChat(userDetails.customerId));
      }
      setOpen((prevState: boolean) => !prevState);
    }
  }, [unsupportedBrowser, setOpen, dispatch, userDetails]);

  return (
    <>
      <Drawer
        open={open}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: InsureAssistStyles.drawerPaper,
        }}
      >
        <Box sx={InsureAssistStyles.container}>
          <Box sx={InsureAssistStyles.header}>
            <Typography sx={InsureAssistStyles.headerTitle}>
              InsureAssist
            </Typography>
            <CloseIcon
              onClick={() => setAlertDialogOpen(true)}
              sx={InsureAssistStyles.closeButton}
            />
          </Box>
          <Box
            sx={{
              ...InsureAssistStyles.messagesContainer,
              overflowY: messages.length > 0 ? "auto" : "hidden",
            }}
          >
            {messages.map((message, index) =>
              message.content ? (
                <Box
                  key={`${message.creationTime}_${index}`}
                  ref={index === messages.length - 1 ? newMessageRef : null}
                  sx={{
                    ...InsureAssistStyles.messageBox,
                    justifyContent:
                      message.sender === "customer" ? "flex-end" : "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      ...InsureAssistStyles.messageContent,
                      background:
                        message.sender === "customer"
                          ? "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)"
                          : "none",
                      backgroundColor:
                        message.sender === "customer"
                          ? "#e5f6fe"
                          : "rgba(244,244,244,var(--tw-bg-opacity))",
                      minWidth: message.type === "audio" ? "80%" : "20%",
                    }}
                  >
                    <Typography sx={InsureAssistStyles.messageSender}>
                      {message.sender === "customer" ? "You" : "InsureAssist"}
                    </Typography>
                    <Box
                      sx={{
                        ...InsureAssistStyles.messageContentBox,
                        display: "flex",
                        flexDirection: "column",
                        gap: "6px",
                      }}
                    >
                      {message.type === "audio" ? (
                        <>
                          <audio
                            controls
                            src={message.content as string}
                            style={{ width: "100%" }}
                          ></audio>
                          {(transcribeLoading &&
                            message.level === messages.length - 1) ||
                          message.level === messages.length ? (
                            <Box sx={InsureAssistStyles.loadingBox}>
                              <Skeleton animation="wave" />
                            </Box>
                          ) : (
                            <></>
                          )}
                          {transcriError ? (
                            <Typography sx={{ color: "red" }}>
                              Error transcribing audio
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {message.transcription && (
                            <Typography className="messageStyles">
                              {message.transcription}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <ReactMarkdown className="messageStyles">
                          {message.content}
                        </ReactMarkdown>
                      )}
                    </Box>
                    <Typography sx={InsureAssistStyles.messageTimestamp}>
                      {new Date(message.creationTime).toLocaleTimeString()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )
            )}

            {loading ? (
              <Box sx={InsureAssistStyles.loadingBox}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <Box>
            <Box sx={InsureAssistStyles.footer} onClick={handleClearChat}>
              <Button
                variant="outlined"
                sx={InsureAssistStyles.clearChatButton}
                color="error"
              >
                Clear Chat
              </Button>
            </Box>
            <form onSubmit={sendMessage} style={{ padding: "10px" }}>
              <FormControl
                sx={InsureAssistStyles.formControl}
                variant="outlined"
              >
                <InputLabel>{isRecording ? "" : "Query"}</InputLabel>
                <OutlinedInput
                  label={isRecording ? "" : "Query"}
                  placeholder={
                    isRecording
                      ? "Recording"
                      : "Please enter your query here..."
                  }
                  size="medium"
                  value={message}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setMessage(e.target.value)
                  }
                  autoFocus={!isMobile}
                  sx={{ position: "relative" }}
                  startAdornment={
                    isRecording ? (
                      <InputAdornment position="start">
                        {elapsedTime}
                      </InputAdornment>
                    ) : null
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      sx={{ display: "flex", gap: "8px" }}
                    >
                      <Tooltip
                        title={
                          isRecording ? "Stop Recording" : "Start Recording"
                        }
                        arrow
                      >
                        <IconButton
                          sx={{
                            ...InsureAssistStyles.micIconButton,
                            border: isRecording ? "2px solid #ff0000" : "none",
                            animation: isRecording
                              ? "blink-animation 1.5s infinite"
                              : "none",
                          }}
                          edge="end"
                          onClick={
                            isRecording
                              ? handleStopRecording
                              : handleStartRecording
                          }
                          disabled={loading}
                        >
                          <MicIcon sx={InsureAssistStyles.sendIconButton} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send Message" arrow>
                        <IconButton
                          type="submit"
                          disabled={isRecording || loading}
                        >
                          <SendIcon sx={InsureAssistStyles.sendIconButton} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </form>
          </Box>
        </Box>
      </Drawer>
      <Dialog open={alertDialogOpen} onClose={handleClose}>
        <DialogTitle>Are you sure to close the chat?</DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={() => setAlertDialogOpen(false)}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <style>{InsureAssistStyles.blinkAnimation}</style>
    </>
  );
};

export default InsureAssist;
