import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { IPaymentFailed } from "../../models/user/payment/Payment";
import paymentStyle from "./PaymentStyle";

const PaymentCancel: React.FC = () => {
  const { errorCode } = useParams();
  const navigate = useNavigate();
  let errorData: IPaymentFailed | null = null;
  if (errorCode) {
    errorData = JSON.parse(atob(errorCode));
  }

  const handleHome = () => navigate("/customer/dashboard");

  return (
    <Box sx={paymentStyle.pSuccesMain}>
      <Box sx={paymentStyle.pSuccessMai2}>
        <Box sx={paymentStyle.pSuccessMsgSec}>
          <Box>
            <ErrorOutlineIcon fontSize="large" sx={paymentStyle.pFailIcon} />
          </Box>
          <Box sx={{ marginTop: "0.5rem" }}>
            <Typography sx={paymentStyle.pFailMsg}> Payment Failed </Typography>
          </Box>
        </Box>
        <Box sx={paymentStyle.pSuccessSecBox}>
          <Box sx={{ width: "100%" }}>
            <Typography sx={{ ...paymentStyle.textFont, textAlign: "center" }}>
              {errorData?.message}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ...paymentStyle.paySection, justifyContent: "center" }}>
          <Box>
            <Button
              onClick={handleHome}
              type="button"
              name="home"
              sx={{ ...paymentStyle.payBtn, margin: "2rem 0 1rem 0" }}
            >
              Home
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentCancel;
