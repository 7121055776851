import { Box, Button, Card, Typography, useMediaQuery } from "@mui/material";
import attentionsIcon from "../../../../assets/svgs/attensionsIcon.svg";
import { AlertStyles } from "./AlertStyles";
import moment from "moment";
import { dateFormat } from "../../../../constants/Constants";
import { currencyFormat } from "../../../../utils/Utility";
import { IAlert } from "../../../../models/user/alert/alert";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";

interface Props {
  alert: IAlert;
  handlePayNow?: (amount: string, carrierPolicyNumber: string) => void;
}
const Alert: React.FC<Props> = ({ alert, handlePayNow }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  return (
    <Card sx={AlertStyles.attentionCard}>
      <CustomIcon iconName="Attention" />
      {/* <img src={attentionsIcon} alt="attentions-icon" /> */}
      <Box sx={AlertStyles.attentionCardItem}>
        {alert.isLegalNotice ? (
          <Typography
            sx={{ ...AlertStyles.attentionCardItemHeadingStyle, color: "red" }}
          >
            Cancellation Notice
          </Typography>
        ) : (
          <></>
        )}
        <Typography sx={AlertStyles.attentionCardItemHeadingStyle}>
          {`${alert.carrierPolicyNo} | ${alert.insuredName}`}
        </Typography>
        {alert.dueAmount && alert.dueDate ? (
          <Box
            sx={{
              display: "flex",
              "@media (max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Typography sx={AlertStyles.attentionCardItemContentStyle}>
              Due amount :{" "}
              {currencyFormat("us", +parseFloat(alert.dueAmount).toFixed(2))}
              {isMobile || !alert.dueDate ? "" : "|"}
            </Typography>
            <Typography sx={AlertStyles.attentionCardItemContentStyle}>
              Due Date : {moment(alert.dueDate).format(dateFormat)}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {alert.isRefundAvailable && alert.refund ? (
          <Box
            sx={{
              display: "flex",
              "@media (max-width:768px)": {
                flexDirection: "column",
              },
            }}
          >
            <Typography sx={AlertStyles.attentionCardItemContentStyle}>
              Refund amount :{" "}
              {currencyFormat(
                "us",
                +parseFloat(alert.refund.amount).toFixed(2)
              )}
              {isMobile || !alert.refund.date ? "" : "|"}
            </Typography>
            <Typography sx={AlertStyles.attentionCardItemContentStyle}>
              Refund Date : {moment(alert.refund.date).format(dateFormat)}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {alert.dueAmount && alert.dueDate ? (
        <Box sx={AlertStyles.attentionCardButtonContainer}>
          <Button
            variant="contained"
            sx={AlertStyles.attentionCardButton}
            onClick={() =>
              handlePayNow &&
              alert.dueAmount &&
              alert.carrierPolicyNo &&
              handlePayNow(alert.dueAmount, alert.carrierPolicyNo)
            }
          >
            Pay Now
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default Alert;
