import { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import DashboardStyle from "./DashboardStyle";
import QuickReferences from "./QuickReferences/QuickReferences";
import WelcomeCard from "./WelcomeCard/WelcomeCard";
import Policies from "./Policies/Policies";
import Claims from "./Claims/Claims";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  GetPoliciesData,
  getClaimsData,
  GetDownloadIdCard,
} from "./DashboardAction";
import Alerts from "./Alerts/Alerts";
import { getBufferData } from "../../utils/Utility";
import { IDownloadIdCardParams } from "../../models/user/dashboard/Dashboard";
import Payment from "../Payment/Payment";
import { setDownloadIdCardContent } from "./DashboardSlice";
import CustomDialog from "../../common/CustomDialog/CustomDialog";
import { Api } from "../..";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { isError, isSuccess, calledReducerType } = useAppSelector(
    (store: RootState) => store.common
  );
  const { downloadIdCardContent, policiesData, claimsData } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  const [openPaymentPopup, setOpenPaymentPopup] = useState<boolean>(false);
  const [currentSelectedAmount, setCurrentSelectedAmount] =
    useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("No Policy");

  const policiesRef = useRef<HTMLDivElement | null>(null);

  const handleYourPoliciesClick = (): void => {
    policiesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleDownloadIdCard = (policyId: string) => {
    const requestParams: IDownloadIdCardParams = {
      policyId,
    };
    dispatch(GetDownloadIdCard(requestParams));
  };

  const handlePaymentDialogeClose = () => {
    setOpenPaymentPopup(false);
  };

  const handlePayNow = (amount: string, carrierPolicyNumber: string) => {
    setPolicyNumber(carrierPolicyNumber);
    setCurrentSelectedAmount(amount);
    setOpenPaymentPopup(true);
  };

  const getInitBasePrompt = useCallback(async () => {
    try {
      await Api.get(`ia/${userDetails?.customerId}/init`);
    } catch (error) {
      console.log(error);
    }
  }, [userDetails?.customerId]);

  useEffect(() => {
    if (userDetails && userDetails.customerId) {
      dispatch(GetPoliciesData({ customerId: userDetails?.customerId }));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.customerId &&
      (isSuccess || isError) &&
      calledReducerType === "dashboard/GetPoliciesData"
    ) {
      dispatch(getClaimsData({ customerId: userDetails.customerId }))
        .then(() => {
          getInitBasePrompt();
        })
        .catch((error) => {
          getInitBasePrompt();
        });
    }
  }, [
    userDetails,
    isSuccess,
    calledReducerType,
    isError,
    dispatch,
    getInitBasePrompt,
  ]);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetDownloadIdCard" &&
      downloadIdCardContent
    ) {
      const bufferArray = getBufferData(downloadIdCardContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      const downloadLink = document.createElement("a");
      downloadLink.download = "Insured ID card.pdf";
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch(setDownloadIdCardContent(null));
    }
  }, [calledReducerType, isSuccess, downloadIdCardContent, dispatch]);

  useEffect(() => {
    if (isError && calledReducerType === "GetDownloadIdCard") {
      alert(`Error in downloading document`);
    }
  }, [calledReducerType, isError]);

  return (
    <Box sx={DashboardStyle.dashboard}>
      <CustomDialog
        showDivider={true}
        open={openPaymentPopup}
        maxWidth="lg"
        onClose={handlePaymentDialogeClose}
        title={`Payment for Policy Number : ${policyNumber}`}
        content={
          <Payment
            amount={currentSelectedAmount}
            carrierPolicyNumber={policyNumber}
          />
        }
      />
      <WelcomeCard handleYourPoliciesClick={handleYourPoliciesClick} />
      <Alerts handlePayNow={handlePayNow} />
      {claimsData &&
      claimsData.claimList &&
      claimsData.claimList.filter(
        (claim) => claim.carrierClaimStatus !== "Closed"
      ).length > 0 ? (
        <Claims claimsData={claimsData} />
      ) : (
        <></>
      )}
      <Box ref={policiesRef}>
        <Policies handleDownloadIdCard={handleDownloadIdCard} />
      </Box>
      <QuickReferences />
    </Box>
  );
};

export default Dashboard;
