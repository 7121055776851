export const ActivityStyles = {
  activity: { padding: "20px 0px", display: "grid", gap: "20px" },
  container: {
    background: "rgba(255, 255, 255, 1)",
    overflow: "visible",
    "@media (min-width:360px) and (max-width:1024px)": {
      display: "grid",
      gap: "20px",
      background: "none",
      boxShadow: "none",
    },
  },
  filter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  tableHeadingFilterImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  tableHeadingFilterText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    letterSpacing: "0px",
    textAlign: "left",
    textTransform: "none",
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "5px 0px",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  popoverContent: {
    width: "340px",
    height: "172px",
    padding: "20px 20px 0px 20px",
    gap: "20px",
    borderRadius: "4px",
  },
  textField: {
    width: "300px",
    height: "56px",
    marginBottom: "20px",
    fontFamily: "Noto Sans",
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  popOver: {
    "& .MuiPopover-paper": {
      left: "unset !important",
      right: "0px !important",
      "@media (max-width: 1024px)": {
        right: "7px !important",
      },
    },
    "@media (max-width: 599px)": {
      "& .MuiPopover-paper": {
        left: "unset !important",
        right: "7px !important",
      },
    },
  },
};
