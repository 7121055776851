import React, { ReactNode, CSSProperties } from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

interface IProps {
  oppositeContent: ReactNode;
  oppositeContentStyle: any;
  dotStyle?: any;
  children: ReactNode;
}

const CustomTimeline: React.FC<IProps> = (props) => {
  const { oppositeContent, oppositeContentStyle, dotStyle, children } = props;
  return (
    <TimelineItem>
      <TimelineOppositeContent color="textSecondary" sx={oppositeContentStyle}>
        {oppositeContent}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot sx={dotStyle} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>{children}</TimelineContent>
    </TimelineItem>
  );
};

export default CustomTimeline;
