import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import {
  API_GET_USER_LOGOUT,
  API_POST_LOGIN,
  API_POST_SENT_OTP,
  API_POST_VALIDATE_OTP,
} from "../../../Apis/ApiEndPoints";
import { Api } from "../../..";
import { setCookie } from "../../../utils/Utility";
import {
  ILoginRequestParmasWithLoginType,
  ILoginResponse,
  ISendOtpRequestParmas,
  IVerifyOtpRequestParams,
} from "../../../models/user/userAuth/userAuth";

export const login = createAsyncThunk(
  "userAuth/login",
  async (
    { requestParams, loginType }: ILoginRequestParmasWithLoginType,
    { dispatch }
  ) => {
    const response: ILoginResponse = await CustomAction(
      Api.post(`${API_POST_LOGIN}/${loginType}`, requestParams),
      dispatch,
      "userAuth/login"
    );

    // if (response.status === "SUCCESS" && response.data) {
    //   setCookie([
    //     { cookieName: "authenticated", cookieValue: "true" },
    //     { cookieName: "roleCode", cookieValue: "2" },
    //     { cookieName: "id", cookieValue: response.data.customerId },
    //     { cookieName: "name", cookieValue: response.data.customerName },
    //   ]);
    // }
    return response;
  }
);

export const sendOtp = createAsyncThunk(
  "userAuth/sendOtp",
  async (requestParams: ISendOtpRequestParmas, { dispatch }) => {
    await CustomAction(
      Api.post(API_POST_SENT_OTP, requestParams),
      dispatch,
      "userAuth/sendOtp"
    );
  }
);

export const verifyOtp = createAsyncThunk(
  "userAuth/verifyOtp",
  async (requestParams: IVerifyOtpRequestParams, { dispatch }) => {
    const response = await CustomAction(
      Api.post(API_POST_VALIDATE_OTP, requestParams),
      dispatch,
      "userAuth/verifyOtp"
    );
    return response;
  }
);

export const logout = createAsyncThunk(
  "userAuth/logout",
  async (userId: string, { dispatch }) => {
    await CustomAction(
      Api.get(`${API_GET_USER_LOGOUT}/${userId}`),
      dispatch,
      "userAuth/logout"
    );
  }
);
