import { createSlice } from "@reduxjs/toolkit";
import { IPolicyDetailsSlice } from "../../../models/user/policyDetails/PolicyDetails";
import {
  GetInsuranceDocumentContent,
  GetInsuranceDocuments,
  GetLienHolders,
  GetPolicyActivities,
  GetPolicyBillingDetails,
  GetPolicyClaimsTransactions,
  GetPolicyCoveragesAndDiscounts,
  GetPolicyDetailsHeader,
  GetPolicyVehiclesAndDrivers,
} from "./policyDetailsActions";
import { IClaimTransaction } from "../../../models/user/dashboard/Dashboard";

const initialState: IPolicyDetailsSlice = {
  policyDetailsHeader: null,
  policyTransactions: null,
  billingTransactions: null,
  claimsTransactions: null,
  policyVehicles: [],
  policyDrivers: [],
  insuranceDocuments: [],
  lienHolders: [],
  coveragesAndDiscounts: null,
  insuranceDocumentContent: null,
  billingDetails: null,
};

const PolicyDetailsSlice = createSlice({
  name: "policyDetails",
  initialState: initialState,
  reducers: {
    setPolicyDetailsHeader: (state, { payload }) => {
      state.policyDetailsHeader = payload;
    },
    setInsuranceDocumentContent: (state, { payload }) => {
      state.insuranceDocumentContent = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetPolicyDetailsHeader.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        state.policyDetailsHeader = payload.data || null;
      }
    });

    builder.addCase(GetPolicyActivities.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        const { policyTransactions, billingTransactions } = payload.data;
        state.policyTransactions = policyTransactions
          ? policyTransactions.map((item) => ({
              ...item,
              identifier: "policy",
            }))
          : [];
        state.billingTransactions = billingTransactions
          ? billingTransactions.map((item) => ({
              ...item,
              identifier: "billing",
            }))
          : [];
      }
    });

    builder.addCase(
      GetPolicyClaimsTransactions.fulfilled,
      (state, { payload }) => {
        if (payload.status === "SUCCESS") {
          const data: IClaimTransaction[] = [];
          payload.data.forEach((claimTransactions) =>
            data.push(
              ...claimTransactions.claimTransactionHistory.map(
                (claimTransaction: IClaimTransaction) => ({
                  ...claimTransaction,
                  identifier: "claim",
                })
              )
            )
          );
          state.claimsTransactions = data;
        }
      }
    );

    builder.addCase(
      GetPolicyVehiclesAndDrivers.fulfilled,
      (state, { payload }) => {
        if (payload.status === "SUCCESS") {
          state.policyDrivers =
            payload.data && payload.data.drivers ? payload.data.drivers : [];
          state.policyVehicles =
            payload.data && payload.data.vehicles ? payload.data.vehicles : [];
        }
      }
    );

    builder.addCase(GetInsuranceDocuments.fulfilled, (state, { payload }) => {
      state.insuranceDocuments =
        payload.status === "SUCCESS" && payload.data && payload.data.documents
          ? payload.data.documents
          : [];
    });

    builder.addCase(GetLienHolders.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        state.lienHolders = payload.data && payload.data ? payload.data : [];
      }
    });
    builder.addCase(
      GetPolicyCoveragesAndDiscounts.fulfilled,
      (state, { payload }) => {
        state.coveragesAndDiscounts =
          payload.status === "SUCCESS" && payload.data ? payload.data : null;
      }
    );
    builder.addCase(
      GetInsuranceDocumentContent.fulfilled,
      (state, { payload }) => {
        state.insuranceDocumentContent =
          payload && payload.status !== "FAIL" ? payload : null;
      }
    );

    builder.addCase(GetPolicyBillingDetails.fulfilled, (state, { payload }) => {
      state.billingDetails = payload.status === "SUCCESS" ? payload.data : null;
    });
  },
});

export default PolicyDetailsSlice.reducer;
export const { setPolicyDetailsHeader, setInsuranceDocumentContent } =
  PolicyDetailsSlice.actions;
