import { createSlice } from "@reduxjs/toolkit";

import {
  IVehcileDetails,
  IVehicle,
} from "../../../models/user/Vehicle/Vehicle";
import {
  GetAddVehicleFormFields,
  GetInsuredAddressDetails,
  GetOptedAndUnoptedCoverages,
  GetVehicleDetails,
} from "./vehicleActions";

const initialState: IVehicle = {
  vehicleDetails: null,
  addVehicleFormFieldsJson: null,
  insuredAddressDetails: null,
  optedCoverages: [],
  unoptedCoverages: [],
};

const vehcileSlice = createSlice({
  name: "vehicle",
  initialState: initialState,
  reducers: {
    setVehicleDetails: (state, { payload }) => {
      state.vehicleDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAddVehicleFormFields.fulfilled, (state, { payload }) => {
      state.addVehicleFormFieldsJson = payload.data;
    });

    builder.addCase(GetVehicleDetails.fulfilled, (state, { payload }) => {
      state.vehicleDetails = payload.data || null;
    });
    builder.addCase(
      GetInsuredAddressDetails.fulfilled,
      (state, { payload }) => {
        state.insuredAddressDetails = payload.data;
      }
    );
    builder.addCase(
      GetOptedAndUnoptedCoverages.fulfilled,
      (state, { payload }) => {
        state.optedCoverages = payload.data?.optedCoverages || [];
        state.unoptedCoverages = payload.data?.unoptedCoverages || [];
      }
    );
  },
});

export default vehcileSlice.reducer;
export const { setVehicleDetails } = vehcileSlice.actions;
