import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { deleteTask, getTasks } from "../../store/user/tasks/tasksActions";
import {
  Box,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CustomTable from "../../common/CustomTable/CustomTable";
import {
  CloseOutlined,
  DeleteOutline,
  MoreVertOutlined,
} from "@mui/icons-material";
import moment from "moment";
import { dateFormat } from "../../constants/Constants";
import { RootState } from "../../store/store";
import TasksStyles from "./TasksStyles";
import ReactMarkdown from "react-markdown";
import { ITask } from "../../models/user/Tasks/Tasks";
import ErrorIcon from "@mui/icons-material/Error";
import successIcon from "../../assets/success.svg";
import { setTaskMessage } from "../../store/user/tasks/tasksSlice";
import { resetCommonSlice } from "../../store/common/CommonSlice";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const Tasks = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { tasks, taskMessage } = useAppSelector(
    (state: RootState) => state.tasks
  );
  const { isError, calledReducerType, isSuccess } = useAppSelector(
    (state: RootState) => state.common
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const [deleteTaskDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    task: ITask
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedTask(task);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteTaskClick = () => {
    handleMenuClose();
    setDeleteDialogOpen(true);
  };

  const handleDeleteTaskDialogClose = useCallback(() => {
    setDeleteDialogOpen(false);
    setSelectedTask(null);
    dispatch(resetCommonSlice());
    dispatch(setTaskMessage(null));
    if (
      userDetails &&
      userDetails.customerId &&
      isSuccess &&
      calledReducerType === "tasks/deleteTask"
    ) {
      dispatch(getTasks({ customerId: userDetails.customerId }));
    }
  }, [calledReducerType, dispatch, isSuccess, userDetails]);

  const handleTaskOkClick = () => {
    if (selectedTask && selectedTask.taskId) {
      dispatch(
        deleteTask({
          taskId: selectedTask.taskId,
          deleteComments: "Deleting the task",
        })
      );
    }
  };

  useEffect(() => {
    if (userDetails && userDetails.customerId) {
      dispatch(getTasks({ customerId: userDetails.customerId }));
    }
  }, [userDetails, dispatch]);

  const columns = [
    {
      label: "Task ID",
      accessorKey: "taskId",
    },
    {
      label: "Name",
      accessorKey: "name",
    },
    {
      label: "Description",
      accessorKey: "description",
    },
    {
      label: "Note",
      accessorKey: "note",
      Cell: (task: any) => (
        <ReactMarkdown className="">{task.note}</ReactMarkdown>
      ),
    },
    {
      label: "Status",
      accessorKey: "statusCd",
    },
    {
      label: "Transaction Date",
      accessorKey: "addDt",
      Cell: (task: any) => (
        <Typography
          sx={{
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "24px",
            whiteSpace: "initial",
          }}
        >
          {`${moment(task.addDt).format(dateFormat)}`}
        </Typography>
      ),
    },
    {
      label: "",
      accessorKey: "",
      Cell: (task: any) => (
        <IconButton onClick={(event) => handleMoreClick(event, task)}>
          <MoreVertOutlined sx={{ cursor: "pointer" }} />
        </IconButton>
      ),
    },
  ];

  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    navigate("/customer/dashboard");
  }, [navigate]);
  return (
    <>
      <Box sx={{ fontSize: "20px", marginTop: "1rem", marginLeft: "1rem" }}>
        <Link
          onClick={handleNavigate}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#1D252B",
            fontFamily: "Noto Sans",
            fontSize: "18px",
            fontWeight: 400,
            cursor: "pointer",
            "@media (max-width: 599px)": {
              fontSize: "14px",
            },
            "@media (min-width: 600px) and (max-width: 1024px)": {
              fontSize: "16px",
            },
            "@media (min-width: 1024px) and (max-width: 1920px)": {
              fontSize: "18px",
            },
          }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
          <Typography>Dashboard</Typography>
        </Link>
      </Box>
      <Box sx={TasksStyles.tasks}>
        <Typography sx={TasksStyles.heading}>Tasks</Typography>
        <CustomTable
          columns={columns}
          data={tasks}
          styles={{ maxHeight: "70vh" }}
          stickyHeader={true}
        />
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleDeleteTaskClick}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <DeleteOutline sx={{ fontSize: "20px" }} />
            <Typography>Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <Dialog open={deleteTaskDialogOpen} onClose={handleDeleteTaskDialogClose}>
        {taskMessage ||
        (isError && calledReducerType === "policyChange/PostRemoveVehicle") ? (
          <>
            <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CloseOutlined
                onClick={handleDeleteTaskDialogClose}
                sx={{ cursor: "pointer" }}
              />
            </DialogTitle>
            <DialogContent
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
                padding: "20px",
              }}
            >
              {taskMessage ? (
                <img src={successIcon} alt="success-icon" />
              ) : (
                <ErrorIcon sx={{ color: "red" }} />
              )}
              {taskMessage ? (
                <DialogContentText>{taskMessage}</DialogContentText>
              ) : (
                <DialogContentText sx={{ color: "red" }}>
                  Something went wrong please try again...
                </DialogContentText>
              )}
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>Are you sure to delete this task?</DialogTitle>
            <DialogActions>
              <Button variant="contained" onClick={handleDeleteTaskDialogClose}>
                Cancel
              </Button>
              <Button variant="outlined" onClick={handleTaskOkClick}>
                Ok
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Tasks;
