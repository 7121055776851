import { Box, Card, Drawer, Typography } from "@mui/material";
import WelcomeCardStyle from "./WelcomeCardStyle";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import React, { useMemo, useState } from "react";
import { getClaimsData } from "../DashboardAction";
import FileAClaim from "../../../components/FileAClaim/FileAClaim";
import { setPolicyDetailsHeader } from "../../../store/user/policyDetails/policyDetailsSlice";
import CustomIcon from "../../../common/CustomIcon/CustomIcon";

interface Props {
  handleYourPoliciesClick: () => void;
}

const WelcomeCard: React.FC<Props> = ({ handleYourPoliciesClick }) => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { policiesData } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const [fileAClaimOpen, setFileAClaimOpen] = useState<boolean>(false);

  const time = useMemo(
    () => new Date().toLocaleTimeString().split(" ")[0].split(":"),
    []
  );

  const isAM = useMemo(
    () => new Date().toLocaleTimeString().split(" ")[1] === "AM",
    []
  );

  const toggleDrawer = (): void => {
    dispatch(setPolicyDetailsHeader(null));
    setFileAClaimOpen(!fileAClaimOpen);
  };

  const handleCloseFileAClaim = (): void => {
    setFileAClaimOpen(!fileAClaimOpen);
    if (userDetails?.customerId) {
      dispatch(getClaimsData({ customerId: userDetails?.customerId }));
    }
  };

  return (
    <>
      <Box sx={WelcomeCardStyle.welcomeCardContainerStyle}>
        <Typography sx={WelcomeCardStyle.welcomeCardTextStyle}>
          Good{" "}
          {isAM ? "Morning" : parseInt(time[0]) > 6 ? "Evening" : "Afternoon"} ,{" "}
          {userDetails?.customerName} ! Need help today?
        </Typography>
        <Card sx={WelcomeCardStyle.welcomeCardStyle} aria-label="welcome Card">
          <Box
            sx={WelcomeCardStyle.welcomeCardItemStyle}
            onClick={handleYourPoliciesClick}
          >
            <CustomIcon iconName="PaymentHistory" />
            <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
              My Policies
            </Typography>
          </Box>
          <Box
            sx={WelcomeCardStyle.welcomeCardItemStyle}
            onClick={toggleDrawer}
          >
            <CustomIcon iconName="Star" />
            <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
              New Claim
            </Typography>
          </Box>
          <Box sx={WelcomeCardStyle.welcomeCardItemStyle}>
            <CustomIcon iconName="RoadsideAssistance" />
            <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
              Roadside Assistance
            </Typography>
          </Box>
        </Card>
      </Box>
      <FileAClaim open={fileAClaimOpen} setOpen={setFileAClaimOpen} />
    </>
  );
};

export default WelcomeCard;
