import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Box,
  Card,
  Typography,
  MenuItem,
  Popover,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import filterIcon from "../../../../assets/filter.svg";
import { dateFormat, filterText } from "../../../../constants/Constants";
import { ActivityStyles } from "./ActivityStyles";
import ActivityCard from "./ActivityCard/ActivityCard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../store/store";
import BillingActivityCard from "./BillingActivityCard/BillingActivityCard";
import {
  IDocument,
  IDocumentContentRequest,
} from "../../../../models/user/policyDetails/Document";
import { getBufferData } from "../../../../utils/Utility";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import moment from "moment";
import CustomTimeline from "../../../../common/CustomTimeline/CustomTimeline";
import { getClaimsData } from "../../../Dashboard/DashboardAction";
import { IClaimId } from "../../../../models/user/policyDetails/PolicyDetails";
import ClaimActivityCard from "./ClaimActivityCard/ClaimActivityCard";
import {
  GetInsuranceDocumentContent,
  GetPolicyActivities,
  GetPolicyClaimsTransactions,
} from "../../../../store/user/policyDetails/policyDetailsActions";
import { IClaim } from "../../../../models/user/dashboard/Dashboard";

interface IFilterType {
  label: string;
  value: string;
}

interface IDuration {
  value: string;
  label: string;
}

const filterTypes: IFilterType[] = [
  { label: "All", value: "all" },
  { label: "Policy", value: "policy" },
  { label: "Billing", value: "billing" },
  { label: "Claims", value: "claim" },
];

const durations: IDuration[] = [
  { value: "30", label: "Last Month" },
  { value: "90", label: "Last 3 Months" },
  { value: "180", label: "Last 6 Months" },
  { value: "365", label: "Last Year" },
];

type Props = {};

const Activity: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const {
    policyDetailsHeader,
    policyTransactions,
    billingTransactions,
    claimsTransactions,
    insuranceDocumentContent,
  } = useAppSelector((state: RootState) => state.policyDetails);
  const { claimsData } = useAppSelector((state: RootState) => state.dashboard);
  const { isSuccess, calledReducerType, isError } = useAppSelector(
    (state: RootState) => state.common
  );

  const [activityData, setActivityData] = useState<any[]>([]);
  const [filterType, setFilterType] = useState<string>("all");
  const [duration, setDuration] = useState<string>("365");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [currentClickFile, setCurrentClickFile] = useState<IDocument | null>(
    null
  );
  const [actionType, setActionType] = useState<string | null>(null);
  const isMobile = useMediaQuery("(max-width:1024px)");

  const handleFileClick = (type: string, document: IDocument | null) => {
    if (!policyId || !document) return;
    setActionType(type);
    setCurrentClickFile(document);
    const requestParams: IDocumentContentRequest = {
      policyId,
      documentId: document.documentId,
    };
    dispatch(GetInsuranceDocumentContent(requestParams));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStartDate = useCallback((selectedDate: string = "365") => {
    const currentDate = new Date();
    let startDate;
    switch (selectedDate) {
      case "30":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate()
        );
        break;
      case "90":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 3,
          currentDate.getDate()
        );
        break;
      case "180":
        startDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 6,
          currentDate.getDate()
        );
        break;
      case "365":
      default:
        startDate = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        break;
    }
    return startDate;
  }, []);

  const getConsolidatedActivity = useCallback(() => {
    const dataArray: any[] = [];
    if (policyTransactions) dataArray.push(...policyTransactions);
    if (billingTransactions) dataArray.push(...billingTransactions);
    if (claimsTransactions) dataArray.push(...claimsTransactions);
    return dataArray;
  }, [policyTransactions, billingTransactions, claimsTransactions]);

  const filteredActivityData = useMemo(() => {
    const period = getStartDate(duration);
    return getConsolidatedActivity()
      .filter((transaction) => {
        const transactionDate = new Date(transaction.transactionDate);
        return transactionDate >= period;
      })
      .filter(
        (transaction) =>
          filterType === "all" || transaction.identifier === filterType
      )
      .sort(
        (a, b) =>
          new Date(b.transactionDate).getTime() -
          new Date(a.transactionDate).getTime()
      );
  }, [getStartDate, getConsolidatedActivity, duration, filterType]);

  const handleFilterTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterType(event.target.value);
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(event.target.value);
  };

  const renderTransactions = () => {
    if (activityData.length === 0)
      return (
        <Box sx={{ padding: "30px" }}>
          <Typography>No Activity Found</Typography>
        </Box>
      );
    return activityData.map((transaction, index) =>
      transaction.identifier === "policy" ? (
        <CustomTimeline
          key={`policy-activity-${index}`}
          oppositeContent={moment(transaction.transactionDate).format(
            dateFormat
          )}
          oppositeContentStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0.3rem",
          }}
          dotStyle={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
          children={
            <ActivityCard
              transaction={transaction}
              isLast={
                index ===
                (isMobile ? activityData.length - 2 : activityData.length - 1)
              }
              handleFileClick={handleFileClick}
            />
          }
        />
      ) : transaction.identifier === "billing" ? (
        <CustomTimeline
          key={`billing-activity-${index}`}
          oppositeContent={moment(transaction.transactionDate).format(
            dateFormat
          )}
          oppositeContentStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0.3rem",
          }}
          dotStyle={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
          children={
            <BillingActivityCard
              transaction={transaction}
              isLast={
                index ===
                (isMobile ? activityData.length - 2 : activityData.length - 1)
              }
            />
          }
        />
      ) : transaction.identifier === "claim" ? (
        <CustomTimeline
          key={`policy-activity-${index}`}
          oppositeContent={moment(transaction.transactionDate).format(
            dateFormat
          )}
          oppositeContentStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0.3rem",
          }}
          dotStyle={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
          children={
            <ClaimActivityCard
              transaction={transaction}
              isLast={
                index ===
                (isMobile ? activityData.length - 2 : activityData.length - 1)
              }
            />
          }
        />
      ) : null
    );
  };

  useEffect(() => {
    if (policyId && policyDetailsHeader?.accountId) {
      dispatch(
        GetPolicyActivities({
          policyId,
          accountId: policyDetailsHeader.accountId,
        })
      );
    }
  }, [policyId, policyDetailsHeader?.accountId, dispatch]);

  useEffect(() => {
    if (userDetails?.customerId) {
      dispatch(getClaimsData({ customerId: userDetails?.customerId }));
    }
  }, [userDetails?.customerId, dispatch]);

  useEffect(() => {
    if (
      policyDetailsHeader &&
      policyDetailsHeader.carrierPolicyNumber &&
      claimsData &&
      claimsData.claimList &&
      claimsData.claimList.length > 0
    ) {
      const claimIds: IClaimId[] = claimsData.claimList
        .filter(
          (claim: IClaim) =>
            claim.carrierPolicyNo === policyDetailsHeader.carrierPolicyNumber
        )
        .map((claim: IClaim) => ({
          claimId: claim.claimId,
        }));
      dispatch(GetPolicyClaimsTransactions(claimIds));
    }
  }, [
    claimsData,
    policyDetailsHeader,
    policyDetailsHeader?.carrierPolicyNumber,
    dispatch,
  ]);

  useEffect(() => {
    setActivityData(filteredActivityData);
  }, [filteredActivityData]);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetPolicyDocumentContent" &&
      insuranceDocumentContent &&
      actionType
    ) {
      const bufferArray = getBufferData(insuranceDocumentContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      if (actionType === "view") {
        window.open(url, "_blank");
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.download = `New Business Package-Apr 20, 2024.pdf`;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }, [
    isSuccess,
    calledReducerType,
    insuranceDocumentContent,
    actionType,
    currentClickFile,
  ]);

  useEffect(() => {
    if (
      isError &&
      calledReducerType === "GetPolicyDocumentContent" &&
      actionType
    ) {
      alert(`Error in ${actionType} document`);
    }
  }, [isError, calledReducerType, actionType]);

  return (
    <Box sx={ActivityStyles.activity}>
      <Box sx={ActivityStyles.filter}>
        <Button sx={ActivityStyles.filterButton} onClick={handleClick}>
          <Box sx={ActivityStyles.tableHeadingFilterImage}>
            <img src={filterIcon} alt="filter" />
          </Box>
          <Typography sx={ActivityStyles.tableHeadingFilterText}>
            {filterText}
          </Typography>
        </Button>
        <Popover
          id={anchorEl ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={ActivityStyles.popOver}
        >
          <Box sx={ActivityStyles.popoverContent}>
            <TextField
              select
              label="Type"
              variant="outlined"
              fullWidth
              value={filterType}
              sx={ActivityStyles.textField}
              onChange={handleFilterTypeChange}
            >
              {filterTypes.map((filter) => (
                <MenuItem key={filter.value} value={filter.value}>
                  {filter.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Period"
              variant="outlined"
              fullWidth
              value={duration}
              sx={ActivityStyles.textField}
              onChange={handlePeriodChange}
            >
              {durations.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Popover>
      </Box>
      <Card sx={ActivityStyles.container}>
        <Timeline
          sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.1 } }}
        >
          {renderTransactions()}
        </Timeline>
      </Card>
    </Box>
  );
};

export default Activity;
