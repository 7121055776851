import React, { useEffect } from "react";
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { address, contactNumber, names } from "../../../../constants/Constants";
import LienHoldersStyles from "./LienHoldersStyles";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import CustomTable from "../../../../common/CustomTable/CustomTable";
import { useParams } from "react-router-dom";
import { ILienHolder } from "../../../../models/user/policyDetails/PolicyDetails";
import { GetLienHolders } from "../../../../store/user/policyDetails/policyDetailsActions";

const LienHolders: React.FC = () => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { lienHolders } = useAppSelector(
    (state: RootState) => state.policyDetails
  );

  const columns = [
    {
      label: "Vehicle Description",
      accessorKey: "vehicleDescription",
    },
    {
      label: "Name",
      accessorKey: "name",
    },
    {
      label: "Address",
      accessorKey: "address",
      Cell: (lienHolder: any) => {
        return lienHolder.address
          ? `${lienHolder.address.addr1} ${lienHolder.address.city} ${lienHolder.address.stateProvCd} ${lienHolder.address.regionISOCd} ${lienHolder.address.postalCode}`
          : "";
      },
    },
    {
      label: "Contact Number",
      accessorKey: "contactNumber",
      Cell: (lienHolder: any) => {
        return lienHolder.contactNumber ? `${lienHolder.contactNumber}` : "--";
      },
    },
  ];

  const ResponsiveLienHoldersTabComponent: React.FC = () => {
    return (
      <Box sx={LienHoldersStyles.leinholderMobileContainer}>
        {lienHolders.length > 0 ? (
          lienHolders.map((lienHolder: ILienHolder, index: number) => (
            <Box sx={LienHoldersStyles.container} key={index}>
              <Box sx={LienHoldersStyles.cardContent}>
                <Typography sx={LienHoldersStyles.descriptionText}>
                  Vehicle Description
                </Typography>
                <Typography sx={LienHoldersStyles.contentText}>
                  {lienHolder.vehicleDescription}
                </Typography>
              </Box>
              <Box sx={LienHoldersStyles.cardContent}>
                <Typography sx={LienHoldersStyles.descriptionText}>
                  {names}
                </Typography>
                <Typography sx={LienHoldersStyles.contentText}>
                  {lienHolder.name}
                </Typography>
              </Box>
              <Box sx={LienHoldersStyles.cardContent}>
                <Typography sx={LienHoldersStyles.descriptionText}>
                  {contactNumber}
                </Typography>
                <Typography sx={LienHoldersStyles.contentText}>
                  {lienHolder.contactNumber}
                </Typography>
              </Box>
              <Box sx={LienHoldersStyles.cardContent}>
                <Typography sx={LienHoldersStyles.descriptionText}>
                  {address}
                </Typography>
                <Typography sx={LienHoldersStyles.contentText}>
                  {`${lienHolder.address.addr1} ${lienHolder.address.city} ${lienHolder.address.stateProvCd} ${lienHolder.address.regionISOCd} ${lienHolder.address.postalCode}`}
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Card sx={LienHoldersStyles.noLienHoldersCard}>
            <Typography sx={LienHoldersStyles.noLienHoldersText}>
              No Records Found
            </Typography>
          </Card>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (policyId) {
      dispatch(GetLienHolders({ policyId }));
    }
  }, [policyId, dispatch]);

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      aria-label="leinholder container"
      sx={LienHoldersStyles.leinholderContainer}
    >
      {!isMobile ? <CustomTable columns={columns} data={lienHolders} /> : <></>}
      <Box sx={LienHoldersStyles.cardContainer}>
        <ResponsiveLienHoldersTabComponent />
      </Box>
    </Box>
  );
};

export default LienHolders;
