import { Box, Typography } from "@mui/material";
import React from "react";
import mail from "../../../../assets/mail.svg";
import phone from "../../../../assets/phone.svg";
import ContactComponentStyles from "./ContactComponentStyles";
import { businessAddress } from "../../../../constants/Constants";
import { useAppSelector } from "../../../../store/hooks";

const ContactButtonComponent: React.FC = () => {
  const { policyDetailsHeader } = useAppSelector(
    (state) => state.policyDetails
  );

  return (
    <Box sx={ContactComponentStyles.contactContainer}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography variant="body1" sx={ContactComponentStyles.heading}>
          Agent Details
        </Typography>
        <Typography variant="body1" sx={ContactComponentStyles.content}>
          {policyDetailsHeader?.producerDetails.nameInfo}
        </Typography>
        <Box sx={ContactComponentStyles.contactContactNumber}>
          <Box sx={ContactComponentStyles.contactPhoneLogo}>
            {<img src={phone} alt="Phone" />}
          </Box>
          <Typography variant="body1" sx={ContactComponentStyles.contactNumber}>
            {policyDetailsHeader?.producerDetails.phoneInfo}
          </Typography>
        </Box>
        <Box sx={ContactComponentStyles.contactContactNumber}>
          <Box sx={ContactComponentStyles.contactMailLogo}>
            {<img src={mail} alt="Mail" />}
          </Box>
          <Typography sx={ContactComponentStyles.contactNumber}>
            {policyDetailsHeader?.producerDetails.emailInfo}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={ContactComponentStyles.heading}>Agency Name</Typography>
        <Typography sx={ContactComponentStyles.contactInsuranceCompany}>
          {policyDetailsHeader?.producerDetails.agencyName}
        </Typography>
        <Typography sx={ContactComponentStyles.contactBusinessAddress}>
          {businessAddress}
        </Typography>
        <Typography sx={ContactComponentStyles.contactAddress}>
          {policyDetailsHeader?.producerDetails.address.addr1}
          {policyDetailsHeader?.producerDetails.address.city}
          {"\n"}
          {policyDetailsHeader?.producerDetails.address.stateProvCd},{"\n"}
          {policyDetailsHeader?.producerDetails.address.regionISOCd}.
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactButtonComponent;
