export const API_POST_LOGIN = "login/user";
export const API_POST_POLICY_DETAILS_HEADER = "policy/header";
export const API_POST_POLICY_ACTIVITIES = "policy/activity";
export const API_POST_POLICY_CLAIMS_ACTIVITIES = "claim/history/details";
export const API_POST_POLICIES = "dashboard/customer/policies";
export const API_POST_CLAIMS = "dashboard/customer/claims";
export const API_POST_POLICY_VEHICLES_AND_VEHICLES =
  "policy/vehiclesAndDrivers";
export const API_POST_INSURANCE_DOCUMENTS = "";
export const API_POST_LEIN_HOLDERS = "policy/lienholder";
export const API_POST_COVERAGES_AND_COVERAGES = "policy/coverages";
export const API_POST_DOCUMENTS = "policy/documents";
export const API_GET_DOCUMENT_CONTENT = "document/policies";
export const API_GET_CLAIM_TRANSACTION_DOCUMENT_CONTENT = "document/claims";
export const POST_FILE_A_CLAIM = "claim/fileAClaim";
export const POST_CLAIM_HISTORY = "claim/history";
export const API_POST_BILLING_DETAILS = "policy/billingDetails";
export const API_GET_DOWNLOAD_ID_CARD = "document/insureCard";
export const API_POST_VEHICLE_DETAILS = "policy/vinValidation";
export const API_GET_ADD_VEHICLE_FORM_SCHEMA = "form/addVehicle";
export const API_GET_LOOKUP_VALUES = "lookup";
export const API_POLICY_CHANGE = "policy/policyChange";
export const API_GET_ADD_DRIVER_FORM_FIELDS_JSON = "form/addDriver";
export const API_GET_FILE_A_CLAIM_FORM_FIELDS_JSON = "form/fnol";
export const API_POST_INSURED_ADDRESS_DETAILS = "policy/insuredAddressDetails";
export const API_GET_TASKS = "dashboard/customer/tasks ";
export const API_POST_OPTED_AND_UNOPTED_COVERAGES = "policy/coverage/options  ";
export const API_POST_DELETE_TASK = "policy/deleteTask";
export const API_POST_SENT_OTP = "auth/sendOtp";
export const API_POST_VALIDATE_OTP = "auth/verifyOtp";
export const API_POST_EMAIL_OR_MOBILE_LOGIN = "login/user/emailOrMobile";
export const API_GET_PREFERENCE_TYPES = "lookup/type/preferences";
export const API_POST_USER_PREFERENCES = "customer/preferences/add";
export const API_GET_USER_PREFERENCES = "customer/preferences/get";
export const API_DELETE_USER_PREFERENCES = "customer/preferences/delete";
export const API_POST_FORM_JSON = "forms/add";
export const API_GET_FORMS = "forms/get";

/** For Lookup */
export const API_GET_LOOKUP_LIST = "lookup/getAll";
export const API_POST_LOOKUP_ADD = "lookup/insert";
export const API_DELETE_LOOKUP_ADD = "lookup";
/** End */

export const API_DELETE_FORM = "forms";
export const API_GET_FEATURES = "features/getAll";
export const API_GET_UI_SETTINGS = "uiSettings/get";
export const API_POST_UI_SETTINGS = "uiSettings/add";
export const API_POST_FEATURES = "features/update ";
export const API_POST_ADMIN_LOGIN = "login/admin";
export const API_GET_USER_LOGOUT = "logout/user";
export const API_GET_ADMIN_LOGOUT = "logout/admin";
