import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import img1 from "../../../assets/images/img1.jpg";
import img3 from "../../../assets/images/img3.jpeg";
import QuickReferencesStyle from "./QuickReferencesStyle";
import img2 from "../../../assets/images/img2.jpg";
import img4 from "../../../assets/images/img4.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import CustomDialog from "../../../common/CustomDialog/CustomDialog";

interface quickReference {
  src: string;
  title: string;
  type: string;
  playing: boolean;
}

type handleReadMoreClick = (title: string) => void;

const QuickReferences = () => {
  const [readMoreModalOpen, setReadMoreModalOpen] = useState<boolean>(false);
  const [readMoreTitle, setReadMoreTitle] = useState<string>("");
  const [quickReferences, setQuickReferences] = useState<quickReference[]>([
    {
      src: img1,
      title: "Navigating Auto Liability Limits.",
      type: "",
      playing: false,
    },
    {
      src: img2,
      title: "How claims work in Personal Auto Policy?",
      type: "",
      playing: false,
    },
    {
      src: img3,
      title: "Know Your Home Owner Policy",
      type: "",
      playing: false,
    },
    {
      src: img4,
      title: "Choosing Your Personal Auto Liability Limits",
      type: "",
      playing: false,
    },
  ]);

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dotsClass: "slickDots",
  };

  const handleVideoPlay = (index: number) => {
    setQuickReferences((prevState) =>
      prevState.map((quickReference, i) =>
        i === index && (i === 0 || i === 2)
          ? { ...quickReference, playing: !quickReference.playing }
          : { ...quickReference, playing: false }
      )
    );
  };

  const getQuickReferences = () => {
    return quickReferences.map((item: quickReference, index: number) => (
      <Card
        key={`QucikReference_${index}`}
        sx={QuickReferencesStyle.qucikReferencesCard}
      >
        {item.type !== "video" && (
          <>
            <CardMedia
              component="img"
              src={item.src}
              alt="green iguana"
              sx={{ height: "180px" }}
            />
            <CardContent sx={QuickReferencesStyle.quickReferencesCardContent}>
              <Typography
                sx={QuickReferencesStyle.quickReferencesCardContentText}
              >
                {item.title}
              </Typography>
              <Button
                variant="outlined"
                sx={QuickReferencesStyle.readMoreBtn}
                onClick={() => handleReadMoreClick(item.title)}
              >
                Read More
              </Button>
            </CardContent>
          </>
        )}
      </Card>
    ));
  };

  const handleReadMoreClick: handleReadMoreClick = (title) => {
    switch (title) {
      case "How claims work in Personal Auto Policy?": {
        setReadMoreTitle("How claims work in Personal Auto Policy?");
        break;
      }
      case "Choosing Your Personal Auto Liability Limits": {
        setReadMoreTitle("Choosing Your Personal Auto Liability Limits");
        break;
      }
      case "Navigating Auto Liability Limits.": {
        setReadMoreTitle("Navigating Auto Liability Limits.");
        break;
      }
      default: {
        setReadMoreTitle("Choosing Your Personal Auto Liability Limits");
      }
    }
    setReadMoreModalOpen(true);
  };

  const getReadMoreContent = (): React.ReactNode => {
    switch (readMoreTitle) {
      case "How claims work in Personal Auto Policy?": {
        return (
          <Box>
            <Typography>
              Understanding how claims work in your personal auto insurance
              policy is crucial when the unexpected happens.
            </Typography>
            <Typography>Here's a breakdown to guide you:</Typography>
            <h3>Types of Claims:</h3>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <h4 style={{ display: "inline" }}>Collision:</h4> Covers damage
                to your car caused by a collision with another vehicle or object
                (e.g., hitting a tree).
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Comprehensive:</h4> Protects
                your car from events other than collisions (e.g., theft,
                vandalism, fire, weather damage).
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Liability:</h4> Pays for
                injuries and property damage you cause to others in an accident.
                Depending on your state, minimum limits may be required.
              </li>
            </ul>
            <h3>Filing a Claim:</h3>
            <ol style={{ marginLeft: "5%" }}>
              <li>
                <h4>Report the incident:</h4>
                <ul style={{ marginLeft: "5%" }}>
                  <li>
                    File a police report if there's an accident, especially with
                    injuries or significant damage.
                  </li>
                  <li>
                    Contact your insurance company as soon as possible. They can
                    guide you through the process.{" "}
                  </li>
                </ul>
              </li>
              <li>
                <h4>Gather Information:</h4>
                <ul style={{ marginLeft: "5%" }}>
                  <li>
                    Collect details like date, time, location, and parties
                    involved (if applicable).
                  </li>
                  <li>
                    Take pictures of the damage to your car and the scene (if
                    safe).
                  </li>
                  <li>
                    Exchange information with other drivers involved, including
                    names, contact details, and insurance companies.
                  </li>
                </ul>
              </li>
              <li>
                <h4>File Officially:</h4>
                <ul style={{ marginLeft: "5%" }}>
                  <li>
                    You can usually file a claim over the phone, online, or
                    through your insurer's mobile app.
                  </li>
                  <li>
                    Be prepared to answer questions about the incident and
                    provide the information you gathered.
                  </li>
                </ul>
              </li>
              <li>
                <h4>The Claim Process: </h4>
                <ul style={{ marginLeft: "5%" }}>
                  <li>
                    Your insurer will assign an adjuster to investigate the
                    claim.
                  </li>
                  <li>
                    They may request additional information, photos, or a police
                    report.
                  </li>
                  <li>
                    The adjuster will assess the damage and determine the repair
                    cost or value of your car (if totaled).
                  </li>
                </ul>
              </li>
              <li>
                <h4>Settlement:</h4>
                <ul style={{ marginLeft: "5%" }}>
                  <li>
                    Based on your coverage and policy limits, your insurer will
                    offer a settlement amount.
                  </li>
                  <li>
                    This could be for repairs, a totaled car payout, or
                    compensation for medical bills and legal costs (depending on
                    your coverage).
                  </li>
                  <li>
                    You have the right to negotiate the settlement if you feel
                    it's inadequate.
                  </li>
                </ul>
              </li>
            </ol>
            <h3>Important Points to Remember:</h3>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <h3 style={{ display: "inline" }}>Deductible:</h3> You'll pay a
                deductible (out-of-pocket amount) before your insurance kicks
                in. The higher your deductible, the lower your premium typically
                is.
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Fault:</h4> Determining fault
                in an accident is crucial. If you're at fault, your claim will
                go through your own insurance, and your rates might increase. If
                the other driver is at fault, you can file a claim with their
                insurance company to avoid impacting yours.
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Documentation:</h4> Keep all
                paperwork related to the accident and claim process, including
                police reports, repair estimates, and communication with your
                insurer.
              </li>
            </ul>
            <Typography>
              By understanding these steps and key points, you'll be better
              prepared to navigate a personal auto insurance claim in the USA.
              Remember, it's always best to consult your specific policy details
              or contact your insurance company or Agent if you have any
              questions.
            </Typography>
          </Box>
        );
      }
      case "Choosing Your Personal Auto Liability Limits": {
        return (
          <Box>
            <Typography>
              Imagine you're driving down the street and accidentally rear-end
              another car at a stoplight. Thankfully, no one is seriously hurt,
              but the other driver's car needs repairs. That's where your auto
              insurance comes in, specifically your{" "}
              <span style={{ fontWeight: "bold" }}>
                personal liability coverage.
              </span>
              This pays for bodily injury and property damage to others that you
              cause in an accident, up to your chosen limits. But how much
              coverage is right for you? Here's how to decide:{" "}
            </Typography>
            <h4>Why Limits Matter:</h4>
            <Typography>
              Let's say your policy has a limit of $25,000 for bodily injury per
              person and $50,000 per accident. In the accident scenario, the
              repairs cost $10,000, which is covered. But what if the injured
              driver has medical bills exceeding $30,000? They could sue you for
              the remaining amount, and you'd be personally responsible if your
              limits aren't enough.
            </Typography>
            <h3>Choosing the Right Coverage:</h3>
            <Typography>Here's what to consider:</Typography>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <h4 style={{ display: "inline" }}>Your Assets:</h4> Similar to
                homeowners insurance, if you have significant assets (a nice
                car, savings, a house), higher limits protect them from being
                used to pay a lawsuit exceeding your coverage.
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Driving Habits:</h4> Do you
                commute daily in heavy traffic? Do you drive young passengers?
                Consider a higher limit if your risk of accidents is higher.
              </li>
            </ul>
            <h3>Real-World Example:</h3>
            <Typography>
              John has a 10-year-old car and limited savings. He chooses a
              minimum coverage option with limits of $25,000/$50,000. Sarah, a
              doctor with a new car and a young child, opts for a higher
              coverage plan with limits of $100,000/$300,000. Both get into
              separate accidents. John's coverage handles the situation.
              However, Sarah's accident results in serious injuries to the other
              driver, with medical bills reaching $150,000. Thankfully, her
              higher limits cover the complete cost, protecting her assets.
            </Typography>
            <h3>General Recommendations:</h3>
            <Typography>
              While minimum limits meet legal requirements, experts recommend:
            </Typography>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  At least $100,000/$300,000
                </span>{" "}
                in coverage for most drivers.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  $500,000 or $1 million
                </span>{" "}
                for those with high assets or extra risk factors (e.g.,
                ride-sharing drivers).
              </li>
            </ul>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Remember:</span>
              Talk to your insurance agent! They can assess your needs and
              recommend suitable limits. Don't be afraid to shop around and
              compare quotes for the best coverage at an affordable price.
              Regularly review your limits as your situation changes to ensure
              you're adequately protected on the road. By understanding personal
              auto liability limits and choosing the right coverage, you can
              ensure financial security in case of an accident. Don't let a
              mishap turn into a financial nightmare - choose the limits that
              bring you peace of mind.
            </Typography>
          </Box>
        );
      }
      default:
        return (
          <Box>
            <Typography>
              Imagine you're driving down the street and accidentally rear-end
              another car at a stoplight. Thankfully, no one is seriously hurt,
              but the other driver's car needs repairs. That's where your auto
              insurance comes in, specifically your{" "}
              <span style={{ fontWeight: "bold" }}>
                personal liability coverage.
              </span>
              This pays for bodily injury and property damage to others that you
              cause in an accident, up to your chosen limits. But how much
              coverage is right for you? Here's how to decide:{" "}
            </Typography>
            <h4>Why Limits Matter:</h4>
            <Typography>
              Let's say your policy has a limit of $25,000 for bodily injury per
              person and $50,000 per accident. In the accident scenario, the
              repairs cost $10,000, which is covered. But what if the injured
              driver has medical bills exceeding $30,000? They could sue you for
              the remaining amount, and you'd be personally responsible if your
              limits aren't enough.
            </Typography>
            <h3>Choosing the Right Coverage:</h3>
            <Typography>Here's what to consider:</Typography>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <h4 style={{ display: "inline" }}>Your Assets:</h4> Similar to
                homeowners insurance, if you have significant assets (a nice
                car, savings, a house), higher limits protect them from being
                used to pay a lawsuit exceeding your coverage.
              </li>
              <li>
                <h4 style={{ display: "inline" }}>Driving Habits:</h4> Do you
                commute daily in heavy traffic? Do you drive young passengers?
                Consider a higher limit if your risk of accidents is higher.
              </li>
            </ul>
            <h3>Real-World Example:</h3>
            <Typography>
              John has a 10-year-old car and limited savings. He chooses a
              minimum coverage option with limits of $25,000/$50,000. Sarah, a
              doctor with a new car and a young child, opts for a higher
              coverage plan with limits of $100,000/$300,000. Both get into
              separate accidents. John's coverage handles the situation.
              However, Sarah's accident results in serious injuries to the other
              driver, with medical bills reaching $150,000. Thankfully, her
              higher limits cover the complete cost, protecting her assets.
            </Typography>
            <h3>General Recommendations:</h3>
            <Typography>
              While minimum limits meet legal requirements, experts recommend:
            </Typography>
            <ul style={{ marginLeft: "5%" }}>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  At least $100,000/$300,000
                </span>{" "}
                in coverage for most drivers.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  $500,000 or $1 million
                </span>{" "}
                for those with high assets or extra risk factors (e.g.,
                ride-sharing drivers).
              </li>
            </ul>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Remember:</span>
              Talk to your insurance agent! They can assess your needs and
              recommend suitable limits. Don't be afraid to shop around and
              compare quotes for the best coverage at an affordable price.
              Regularly review your limits as your situation changes to ensure
              you're adequately protected on the road. By understanding personal
              auto liability limits and choosing the right coverage, you can
              ensure financial security in case of an accident. Don't let a
              mishap turn into a financial nightmare - choose the limits that
              bring you peace of mind.
            </Typography>
          </Box>
        );
    }
  };

  const hadleReadMoreDialogClose = () => {
    setReadMoreModalOpen(false);
    setReadMoreTitle("");
  };

  return (
    <>
      <Box sx={QuickReferencesStyle.quickReferences}>
        <Typography sx={QuickReferencesStyle.quickReferencesHeading}>
          Quick References For You
        </Typography>
        <Box sx={QuickReferencesStyle.qucikReferencesCards}>
          {getQuickReferences()}
        </Box>
        <Box sx={QuickReferencesStyle.carousel}>
          <Slider {...slickSettings}>{getQuickReferences()}</Slider>
        </Box>
      </Box>
      <CustomDialog
        open={readMoreModalOpen}
        onClose={hadleReadMoreDialogClose}
        maxWidth="xl"
        title={readMoreTitle}
        content={getReadMoreContent()}
        showDivider={true}
      />
    </>
  );
};

export default QuickReferences;
