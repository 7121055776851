import { Buffer } from "buffer";
import { countryCurrency } from "../constants/Constants";
import { ICookie } from "../models/user/common/Common";

export const setCookie = (data: ICookie[]) => {
  if (data.length <= 0) {
    return;
  }
  data.forEach((item: ICookie) => {
    document.cookie = `${item.cookieName}=${
      item.cookieValue
    }; ${getCookieExpiry()}`;
  });
};

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  let cookieValue = "";
  cookies.forEach((item) => {
    if (item.search(name) !== -1) {
      cookieValue = item.split("=")[1];
    }
  });
  return cookieValue;
};

export const deleteCookie = (...names: any) => {
  names.forEach((item: string) => {
    document.cookie = `${item}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  });
};

export const getCookieExpiry = (days: number = 1) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();

  return expires;
};

export const formatNumber: (number: number | undefined) => string = (
  number
) => {
  return (
    number?.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    }) || ""
  );
};

export const currencyFormat = (countryCode: string, formatNumber: number) => {
  const selectedCountry: any =
    countryCurrency[countryCode as keyof typeof countryCurrency];
  return new Intl.NumberFormat(selectedCountry.name, {
    style: "currency",
    currency: selectedCountry.currency,
  }).format(formatNumber);
};

export const getBufferData = (fileData: any) => {
  const base64Str = Buffer.from(fileData).toString("base64");
  const binaryString = window.atob(base64Str);
  let bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export function formatDateToYYYYMMDD(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const formatBase64String = (
  base64: string,
  mimeType: string
): string => {
  return `data:${mimeType};base64,${base64}`;
};

export const base64ToFile = (
  base64: string,
  filename: string
): File | undefined => {
  const arr = base64.split(",");

  if (arr.length !== 2 || !arr[0].startsWith("data:")) {
    console.error("Invalid base64 string format.");
    return undefined;
  }

  const mimeMatch = arr[0].match(/:(.*?);/);

  if (!mimeMatch) {
    console.error("Invalid base64 string, no mime type found.");
    return undefined;
  }
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  const n = bstr.length;
  const u8arr = new Uint8Array(n);

  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};
