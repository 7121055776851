import { createSlice } from "@reduxjs/toolkit";
import { IProfileSlice } from "../../../models/user/Profile/Profile";
import { getPreferenceTypes, getUserPreferences } from "./profileActions";

const initialState: IProfileSlice = {
  preferenceTypes: [],
  channels: [],
  durations: [],
  userSelectedPreferences: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getPreferenceTypes.fulfilled, (state, { payload }) => {
      state.preferenceTypes = payload.data.preferenceTypes || [];
      state.channels = payload.data.channels || [];
      state.durations = payload.data.durations || [];
    });
    builder.addCase(getUserPreferences.fulfilled, (state, { payload }) => {
      state.userSelectedPreferences = payload.data || [];
    });
  },
});

export default profileSlice.reducer;
export const {} = profileSlice.actions;
