import ActiveClaimsIcon from "../../assets/icons/CustomIcons/ActiveClaimsIcon";
import CarIcon from "../../assets/icons/CustomIcons/CarIcon";
import ClaimsClosedIcon from "../../assets/icons/CustomIcons/ClaimsClosedIcon";
import CoveragesIcon from "../../assets/icons/CustomIcons/CoveragesIcon";
import DiscountIcon from "../../assets/icons/CustomIcons/DiscountIcon";
import DriverIcon from "../../assets/icons/CustomIcons/DriverIcon";
import EditPictureIcon from "../../assets/icons/CustomIcons/EditPictureIcon";
import EmailIcon from "../../assets/icons/CustomIcons/EmailIcon";
import ForgotPasswordIcon from "../../assets/icons/CustomIcons/ForgotPasswordIcon";
import HomeClaimIcon from "../../assets/icons/CustomIcons/HomeClaimIcon";
import HomeIcon from "../../assets/icons/CustomIcons/HomeIcon";
import LearnAboutIcon from "../../assets/icons/CustomIcons/LearnAboutIcon";
import LocationIcon from "../../assets/icons/CustomIcons/LocationIcon";
import NotificationIcon from "../../assets/icons/CustomIcons/NotificationIcon";
import PastClaimsIcons from "../../assets/icons/CustomIcons/PastClaimsIcons";
import PaymentsHistoryIcon from "../../assets/icons/CustomIcons/PaymentsHistoryIcon";
import PhoneIcon from "../../assets/icons/CustomIcons/PhoneIcon";
import PolicyChangeIcon from "../../assets/icons/CustomIcons/PolicyChangeIcon";
import PolicyRenewalIcon from "../../assets/icons/CustomIcons/PolicyRenewalIcon";
import RoadsideAssistanceIcon from "../../assets/icons/CustomIcons/RoadsideAssistanceIcon";
import StarIcon from "../../assets/icons/CustomIcons/StarIcon";
import SuccessIcon from "../../assets/icons/CustomIcons/SuccessIcon";
import { Box } from "@mui/material";
import AttentionIcon from "../../assets/icons/CustomIcons/AttentionIcon";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { defaultUiSetting } from "../../constants/Constants";

interface IProps {
  iconName: string;
}

const CustomIcon = (props: IProps) => {
  const { iconName } = props;
  const { uiSettings } = useAppSelector((state: RootState) => state.uiSettings);

  let defaultColor = defaultUiSetting.iconColor;
  if (uiSettings && uiSettings.iconColor) {
    defaultColor = uiSettings.iconColor;
  }
  const getIcon = () => {
    if (!iconName) {
      return <Box>No Icon found</Box>;
    }
    const defaultStyle = {
      height: "40px",
      width: "40px",
    };
    // const defaultColor = "rgb(255 192 112)"; // "green";
    switch (iconName) {
      case "ActiveClaims":
        return (
          <ActiveClaimsIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "Attention":
        return <AttentionIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Car":
        return <CarIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "ClaimsClosed":
        return (
          <ClaimsClosedIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "Coverages":
        return <CoveragesIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Discount":
        return <DiscountIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Driver":
        return <DriverIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "EditPicture":
        return (
          <EditPictureIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "Email":
        return <EmailIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "ForgotPassword":
        return (
          <ForgotPasswordIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "HomeClaim":
        return <HomeClaimIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Home":
        return <HomeIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "LearnAbout":
        return <LearnAboutIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Location":
        return <LocationIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Notification":
        return (
          <NotificationIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "PastClaims":
        return (
          <PastClaimsIcons fill={defaultColor} otherProps={defaultStyle} />
        );
      case "PaymentHistory":
        return (
          <PaymentsHistoryIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "Phone":
        return <PhoneIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "PolicyChange":
        return (
          <PolicyChangeIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "PolicyRenewal":
        return (
          <PolicyRenewalIcon fill={defaultColor} otherProps={defaultStyle} />
        );
      case "RoadsideAssistance":
        return (
          <RoadsideAssistanceIcon
            fill={defaultColor}
            otherProps={defaultStyle}
          />
        );
      case "Star":
        return <StarIcon fill={defaultColor} otherProps={defaultStyle} />;
      case "Success":
        return <SuccessIcon fill={defaultColor} otherProps={defaultStyle} />;
      default:
        return <span>No Icon found</span>;
    }
  };

  return getIcon();
};

export default CustomIcon;
