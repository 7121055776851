import React from "react";
import { Button, SxProps } from "@mui/material";
import { DOWN_ARROW } from "../../../constants/Constants";

interface WhiteButtonProps {
  buttonText: string;
  buttonStyles?: SxProps;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const WhiteButton: React.FC<WhiteButtonProps> = ({
  buttonText,
  buttonStyles,
  onClick,
}) => {
  return (
    <Button variant="text" sx={buttonStyles} onClick={onClick}>
      {buttonText}
      {buttonText === "More" && <img src={DOWN_ARROW} alt="Dropdown Icon" />}
    </Button>
  );
};

export default WhiteButton;
