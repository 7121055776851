import DynamicForm from "../DynamicForm/DynamicForm";
import {
  Box,
  Button,
  DialogContent,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import AddVehcileStyles from "./AddVehicleFormStyles";
import { CloseOutlined } from "@mui/icons-material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  IAsyncLabelFn,
  IAsyncOptionFn,
  IDynamicFormFieldsJson,
  IGetOptionsResponse,
} from "../../models/user/DynamicForm/DynamicForm";
import {
  API_GET_LOOKUP_VALUES,
  API_POST_INSURED_ADDRESS_DETAILS,
} from "../../Apis/ApiEndPoints";
import { CustomAction } from "../../store/customAction";
import { RootState } from "../../store/store";
import { useParams } from "react-router-dom";
import { PostAddVehicle } from "../../store/user/policyChange/policyChangeActions";
import { IVehiclePolicyChangeRequestParams } from "../../models/user/PolicyChange/PolicyChange";
import {
  GetAddVehicleFormFields,
  GetVehicleDetails,
} from "../../store/user/vehicle/vehicleActions";
import { setVehicleDetails } from "../../store/user/vehicle/vehicleSlice";
import {
  IGetInsuredAddressDetailsResponse,
  IGetVehcileDetailsRequestParams,
} from "../../models/user/Vehicle/Vehicle";
import MessageDialog from "../../components/MessageDialog/MessageDialog";
import { Api } from "../..";

export interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddVehicleForm: React.FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { policyDetailsHeader } = useAppSelector(
    (state) => state.policyDetails
  );
  const { addVehicleFormFieldsJson, vehicleDetails } = useAppSelector(
    (state: RootState) => state.vehicle
  );
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state: RootState) => state.common
  );

  const [vin, setVin] = useState<string>("5GAER237X8J250708");
  const [vinError, setVinError] = useState<string>("");
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const asyncOptionsFunctions: {
    [key: string]: IAsyncOptionFn;
  } = useMemo(
    () => ({
      getVehicleOwnershipOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getVehicleOwnershipOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getInterestTypes: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getInterestTypes"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
    }),
    [dispatch]
  );

  const asyncLabelFunctions: { [key: string]: IAsyncLabelFn } = useMemo(
    () => ({
      getGarageLocation: async () => {
        try {
          const response: IGetInsuredAddressDetailsResponse =
            await CustomAction(
              Api.post(API_POST_INSURED_ADDRESS_DETAILS, { policyId }),
              dispatch,
              "getGarageLocation"
            );
          return `${response.data.addr1} ${response.data.addrTypeCd} ${response.data.city} ${response.data.county} ${response.data.countyCode} ${response.data.postalCode} ${response.data.regionCd} ${response.data.regionISOCd} ${response.data.stateProvCd}`;
        } catch (error) {
          return "";
        }
      },
    }),
    [dispatch, policyId]
  );

  const handleVinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVinError("");
    setVin(e.target.value);
  };

  const handleLookUp = () => {
    if (vin.length !== 17) {
      setVinError("Please enter a valid vin number");
      return;
    }
    if (policyId) {
      const requestParams: IGetVehcileDetailsRequestParams = { vin };
      dispatch(GetVehicleDetails(requestParams));
    }
  };

  const formFieldsJson: IDynamicFormFieldsJson | null =
    useMemo((): IDynamicFormFieldsJson | null => {
      if (vehicleDetails && addVehicleFormFieldsJson) {
        const vehicleDetailsKeys = Object.keys(vehicleDetails);
        return {
          ...addVehicleFormFieldsJson,
          sections: addVehicleFormFieldsJson.sections
            ? addVehicleFormFieldsJson.sections.map((section) => ({
                ...section,
                fields: section.fields.map((field) => {
                  const updatedField = { ...field };
                  if (vehicleDetailsKeys.includes(updatedField.name)) {
                    updatedField.value =
                      vehicleDetails[
                        updatedField.name as keyof typeof vehicleDetails
                      ] ?? undefined;
                  }
                  if (updatedField.name === "carrierPolicyNo") {
                    updatedField.value =
                      policyDetailsHeader?.carrierPolicyNumber;
                  }
                  return updatedField;
                }),
              }))
            : [],
        };
      }
      return null;
    }, [vehicleDetails, addVehicleFormFieldsJson, policyDetailsHeader]);

  const handleAddVehicleFormSubmit = (formValues: { [key: string]: any }) => {
    if (vin.length !== 17) {
      setVinError("Invalid Vin Number");
      return;
    }
    if (userDetails && userDetails.customerId && policyId) {
      const addVehicleReuestParams: IVehiclePolicyChangeRequestParams = {
        carrierPolicyNo: formValues.carrierPolicyNo,
        changeDt: formValues.changeDt.format("YYYY-MM-DD"),
        customerId: userDetails.customerId,
        policyRef: policyId,
        detailDescription: "Adding a vehicle to the  policy from UI",
        vehicle: {
          make: formValues.make,
          manufactureYr: formValues.manufactureYr,
          model: formValues.model,
          vehicleType: formValues.vehicleType,
          vin: vin,
          address: "",
        },
        additionalInterestDetails: {
          accountNumber: "",
          address: formValues.additionalInterestAddress,
          interestName: formValues.interestName,
          interestTypeCd: formValues.interestType,
        },
      };
      dispatch(PostAddVehicle(addVehicleReuestParams));
    }
  };

  const handleClose = useCallback(() => {
    dispatch(setVehicleDetails(null));
    setVin("5GAER237X8J250708");
    setOpen(false);
  }, [dispatch, setOpen]);

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
  };

  useEffect(() => {
    if (open) {
      dispatch(GetAddVehicleFormFields());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (
      (isError &&
        (calledReducerType === "vehicle/GetVehicleDetails" ||
          calledReducerType === "vehicle/GetInsuredAddressDetails")) ||
      ((isSuccess || isError) &&
        calledReducerType === "policyChange/PostAddVehicle")
    ) {
      handleClose();
      setMessageDialogOpen(true);
    }
  }, [isSuccess, isError, calledReducerType, handleClose, setOpen]);

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: {
            width: "50%",
            "@media (max-width:768px)": {
              width: "100%",
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={AddVehcileStyles.heading}>
          <Typography sx={AddVehcileStyles.headingText}>Add vehicle</Typography>
          <CloseOutlined sx={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
        <Box
          sx={{
            padding: "20px 20px 0px 20px",
            display: "grid",
            gridTemplateColumns: "80% 20%",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <Box>
            <TextField
              label="VIN"
              value={vin}
              fullWidth
              placeholder="Please enter the vin number of vehicle to be added"
              onChange={handleVinChange}
              required
            />
            <Typography sx={{ color: "red" }}>{vinError}</Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              fontFamily: "Noto Sans",
              borderRadius: "40px",
              backgroundColor: "rgba(63, 63, 63, 1)",
              boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
            }}
            onClick={handleLookUp}
          >
            Look up
          </Button>
        </Box>
        <DialogContent>
          {formFieldsJson ? (
            <DynamicForm
              formFieldsJson={formFieldsJson}
              onFormSubmit={handleAddVehicleFormSubmit}
              asyncOptionsFunctions={asyncOptionsFunctions}
              asyncLabelFunctions={asyncLabelFunctions}
            />
          ) : (
            <></>
          )}
        </DialogContent>
      </Drawer>
      <MessageDialog
        handleClose={handleMessageDialogClose}
        open={messageDialogOpen}
      />
    </>
  );
};

export default AddVehicleForm;
