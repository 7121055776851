import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getUiSettings,
  postUiSettings,
} from "../../../store/admin/uiSettings/uiSettingsActions";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import { RootState } from "../../../store/store";
import { MuiColorInput } from "mui-color-input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { IUiSettings } from "../../../models/admin/uiSettings/uiSettings";
import CustomIcon from "../../../common/CustomIcon/CustomIcon";

interface IProps {}

const UiSettings: FC<IProps> = () => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const dispatch = useAppDispatch();

  const { uiSettings } = useAppSelector((state: RootState) => state.uiSettings);
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state: RootState) => state.common
  );

  const [formData, setFormData] = useState<IUiSettings>();
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target?.result as string;

        img.onload = () => {
          if (fieldName === "loginBanner") {
            const isValidDimensions = img.width === 788 && img.height === 644;

            if (!isValidDimensions) {
              alert("The login banner must be 788 x 644 pixels.");
              return;
            }
          }

          if (file.type.startsWith("image/")) {
            setFormData((prevState) => ({ ...prevState, [fieldName]: file }));
          } else {
            alert("Please upload a valid image file.");
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = useCallback((key: keyof IUiSettings) => {
    setFormData((prevState) => {
      const newState = { ...prevState };
      delete newState[key];
      return newState;
    });
  }, []);

  const handleCancelClick = useCallback(() => {
    if (uiSettings) {
      setFormData(uiSettings);
    }
  }, [uiSettings]);

  const handleSave = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAlertDialogOpen(true);
  }, []);

  const handleAlertDialogCancelClick = useCallback(() => {
    setFormData({});
    setAlertDialogOpen(false);
  }, []);

  const handleAlertDialogOkClick = useCallback(() => {
    setAlertDialogOpen(false);
    const newFormData = new FormData();
    if (formData) {
      Object.keys(formData).forEach((key) => {
        const file = formData[key as keyof typeof formData];
        if (file) {
          newFormData.append(key, file);
        }
      });
      dispatch(postUiSettings(newFormData));
    }
  }, [dispatch, formData]);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    dispatch(getUiSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUiSettings());
  }, [dispatch]);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "uiSettings/getUiSettings" &&
      uiSettings
    ) {
      setFormData(uiSettings);
    }
  }, [isSuccess, calledReducerType, uiSettings]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "uiSettings/postUiSettings"
    ) {
      setMessageDialogOpen(true);
      setFormData({});
    }
  }, [isSuccess, isError, calledReducerType]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
      >
        <Card>
          <form onSubmit={handleSave}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Logo</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => handleFileChange(event, "logo")}
                    />
                  </Button>
                  <Box>
                    {formData && formData.logo instanceof File ? (
                      <Chip
                        label={formData.logo.name}
                        onDelete={() => handleFileDelete("logo")}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Login Banner</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, "loginBanner")
                      }
                    />
                  </Button>
                  <Box>
                    {formData && formData.loginBanner instanceof File ? (
                      <Chip
                        label={formData.loginBanner.name}
                        onDelete={() => handleFileDelete("loginBanner")}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Primary Button Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.primaryButtonColor
                      ? formData.primaryButtonColor
                      : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      primaryButtonColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Secondary Button Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.secondaryButtonColor
                      ? formData.secondaryButtonColor
                      : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      secondaryButtonColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Icon Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.iconColor ? formData.iconColor : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      iconColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Primary Button Text Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.primaryButtonTextColor
                      ? formData.primaryButtonTextColor
                      : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      primaryButtonTextColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Secondary Button Text Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.secondaryButtonTextColor
                      ? formData.secondaryButtonTextColor
                      : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      secondaryButtonTextColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button
                  variant="contained"
                  sx={{ width: "max-content", marginTop: "20px" }}
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "max-content",
                    marginTop: "20px",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "max-content",
                    marginTop: "20px",
                  }}
                  disabled={formData && Object.keys(formData).length === 0}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </form>
        </Card>
        <Card>
          <CardHeader title="Current Configuration" />
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {uiSettings ? (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1fr",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Typography variant="h5">Logo</Typography>
                  {uiSettings.logo && (
                    <img
                      src={`data:image/png;base64,${uiSettings.logo}`}
                      alt="Logo Preview"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1fr",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Typography variant="h5">Login Banner</Typography>
                  {uiSettings.loginBanner && (
                    <img
                      src={`data:image/png;base64,${uiSettings.loginBanner}`}
                      alt="Login Banner Preview"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Primary Button</Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: uiSettings.primaryButtonColor,
                      color: uiSettings.primaryButtonTextColor,
                    }}
                  >
                    Text
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Secondary Button </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: uiSettings.secondaryButtonColor,
                      color: uiSettings.secondaryButtonTextColor,
                    }}
                  >
                    Text
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Icon</Typography>
                  <CustomIcon iconName="RoadsideAssistance" />
                </Box>
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
      <AlertDialog
        handleCancelClick={handleAlertDialogCancelClick}
        handleOkClick={handleAlertDialogOkClick}
        open={alertDialogOpen}
        title="Saving this configuration will impact the end user, is this okay?"
      />
      <MessageDialog
        handleClose={handleMessageDialogClose}
        open={messageDialogOpen}
      />
    </>
  );
};

export default UiSettings;
