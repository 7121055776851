import { red } from "@mui/material/colors";
const themesVariables = {
  primaryBtnColor: "#000000",
  primaryBtnTextColor: "#ffffff",
  fontText: "14px",
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  variant: "contained",
  errorColor: red.A400,
};

export default themesVariables;
