import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ResponsiveBilling from "./ResponsiveBilling";
import BillingTabStyles from "./BillingStyles";
import { currencyFormat } from "../../../../utils/Utility";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { IBillingRequestParams } from "../../../../models/user/policyDetails/Billings";
import CustomTable, {
  IColumn,
} from "../../../../common/CustomTable/CustomTable";
import moment from "moment";
import { dateFormat } from "../../../../constants/Constants";
import { GetPolicyBillingDetails } from "../../../../store/user/policyDetails/policyDetailsActions";

const BillingTabComponent = () => {
  const dispatch = useAppDispatch();

  const { billingDetails, policyDetailsHeader } = useAppSelector(
    (store: RootState) => store.policyDetails
  );

  useEffect(() => {
    if (policyDetailsHeader?.accountId) {
      const requestParams: IBillingRequestParams = {
        accountId: policyDetailsHeader?.accountId,
      };
      dispatch(GetPolicyBillingDetails(requestParams));
    }
  }, [policyDetailsHeader?.accountId, dispatch]);

  const policyPaymentColumns: IColumn[] = [
    {
      label: "Paid Date",
      accessorKey: "paidDate",
      Cell: (row: any) => {
        return row.paidDate ? (
          moment(row.paidDate).format(dateFormat)
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Noto Sans",
              fontSize: "14px",
              fontWeight: 400,
              whiteSpace: "initial",
            }}
          >
            -
          </Typography>
        );
      },
    },
    {
      label: "Paid Amount",
      accessorKey: "paidAmount",
    },
  ];

  const generatedInvoicesColumns: IColumn[] = [
    {
      label: "Invoice Date",
      accessorKey: "invoiceDate",
      Cell: (row: any) => {
        return row.invoiceDate ? (
          moment(row.invoiceDate).format(dateFormat)
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Noto Sans",
              fontSize: "14px",
              fontWeight: 400,
              whiteSpace: "initial",
            }}
          >
            -
          </Typography>
        );
      },
    },
    {
      label: "Due Date",
      accessorKey: "  dueDate",
      Cell: (row: any) => {
        return row.dueDate ? (
          moment(row.dueDate).format(dateFormat)
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontFamily: "Noto Sans",
              fontSize: "14px",
              fontWeight: 400,
              whiteSpace: "initial",
            }}
          >
            -
          </Typography>
        );
      },
    },
    {
      label: "Invoice Amount",
      accessorKey: "invoiceAmount",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          alignItems: "start",
          padding: "20px",
          gap: "20px",
          width: "100%",
          "@media(max-width:599px)": {
            gridTemplateColumns: "1fr",
          },
        }}
      >
        <Box sx={{ width: "100%", display: "grid", gap: "20px" }}>
          <Typography
            sx={{
              fontFamily: "Playfair Display",
              fontSize: "20px",
              fontHeight: "400",
              textAlign: "left",
            }}
          >
            Invoices
          </Typography>
          <CustomTable
            columns={generatedInvoicesColumns}
            data={billingDetails?.generatedInvoices || []}
          />
        </Box>
        <Box sx={{ width: "100%", display: "grid", gap: "20px" }}>
          <Typography
            sx={{
              fontFamily: "Playfair Display",
              fontSize: "20px",
              fontHeight: "400",
              textAlign: "left",
            }}
          >
            Payments
          </Typography>
          <CustomTable
            columns={policyPaymentColumns}
            data={billingDetails?.policyPayments || []}
          />
        </Box>
      </Box>
      {/* <Box sx={BillingTabStyles.cardContainer}>
        <ResponsiveBilling
          columns={getCloumns()}
          data={[...billingDetails].reverse()}
        />
      </Box> */}
    </Box>
  );
};

export default BillingTabComponent;
