export const DocumentStyles = {
  cardContainer: {
    padding: "0px 30px",
    width: "100%",
    "@media only screen and (max-width: 768px)": {
      padding: "0px 20px",
    },
  },
  card: {
    display: "inline-flex",
    width: "100%",
    gap: "20px",
    borderRadius: "10px",
    marginTop: "22px",
    padding: "30px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    "@media (max-width: 768px)": {
      display: "grid",
      padding: "20px",
    },
  },
  cardContentBox: {
    display: "inline-flex",
    width: "70%",
    "@media only screen and (max-width: 768px)": {
      width: "100%",
    },
  },
  cardItem: {
    display: "grid",
    marginLeft: "1rem",
    "@media only screen and (max-width: 768px)": {
      gridArea: "text",
    },
  },
  cardItemHeadingStyle: {
    color: "#1D252B",
    fontSize: "1rem",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    textOverflow: "ellipsis",
    "@media (max-width:599px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "@media (min-width:600px) and (max-width:1024px)": {
      maxWidth: "500px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "@media (min-width: 1025px) and (max-width: 1366px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  cardItemContentStyle: {
    color: "#1D252B",
    fontSize: "1rem",
    fontFamily: "Noto Sans",
    fontWeight: "300",
    lineHeight: "20px",
    "@media(max-width:599px)": {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#1D252B",
      fontFamily: '"Noto Sans"',
      fontWeight: 300,
    },
    "@media (min-width: 1025px) and (max-width: 1366px)": {
      maxWidth: "900px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  buttonGroup: {
    width: "30%",
    display: "inline-flex",
    justifyContent: "flex-end",
    "@media (max-width: 789px)": {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  walletButton: {
    gap: "4px",
    padding: "0.5rem",
  },
  downloadButton: {
    gap: "4px",
    padding: "0.5rem",
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
    opacity: "0.3",
  },
  text: {
    textTransform: "none",
    color: "#000",
    fontFamily: "Noto Sans",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    "@media (max-width: 789px)": {
      fontSize: "14px",
    },
  },
  imageContainer: {
    height: "55px",
    backgroundColor: "#8e030f",
    borderRadius: "5px",
    "@media (max-width: 789px)": {
      height: "55px",
    },
  },
  pdfIcon: {
    color: "#ffffff",
    margin: "0.9rem",
  },
};
