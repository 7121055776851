export const ActivityCardStyles = {
  activityCard: {
    width: "100%",
    position: "relative",
    height: "auto",
    padding: "20px",
    "@media (max-width:1024px)": {
      border: "1px solid rgba(201, 225, 236, 1)",
      background: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
    },
    "@media (min-width: 1025px)": {
      "&:hover": {
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.09) inset",
        background: "rgba(243, 245, 246, 1)",
      },
    },
    "@media (max-width: 768px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  activityCardContainer: {
    display: "grid",
    gap: "20px",
    gridTemplateColumns: "0.5fr 1fr 1fr 1fr 1fr",
    gridTemplateAreas: `"icon type effective premium more"`,
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `"icon"
                                  "type"
                                  "effective"
                                  "premium"`,
    },
  },
  icon: {
    gridArea: "icon",
    img: {
      width: "40.94px",
      height: "40px",
      "@media (min-width: 360px) and (max-width: 1024px)": {
        width: "46.36px",
        height: "48.88px",
      },
    },
  },
  heading: {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Noto Sans",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: "400",
    },
  },
  description: {
    fontWeight: "300",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: "300",
    },
  },
  hoveredImage: {
    width: "40px",
    height: "40px",
  },
  images: {
    display: "flex",
    alignItems: "Center",
    cursor: "pointer",
    justifyContent: "flex-end",
    gridArea: "more",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      display: "none",
    },
  },
  ellipsis: {
    display: "none",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      right: "10%",
      top: "12%",
    },
  },
};
