const PolicyDetailsStyles = {
  policyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    minHeight: "84vh",
    padding: "30px",
  },
};

export default PolicyDetailsStyles;
