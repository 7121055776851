const PolicyDetailsHeaderStyles = {
  BasicInfoCard: {
    background: "linear-gradient(108deg, #FFF 80.85%, #F8F9FF 145.84%)",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #C9E1EC",
    borderRadius: "10px",
    "@media (max-width: 399px)": {
      flexDirection: "column",
    },
    "@media (min-width: 399px) and (max-width: 799px)": {
      flexDirection: "column",
    },
    "@media (min-width: 799px) and (max-width: 1300px)": {
      flexDirection: "column",
    },
  },

  status: {
    display: "flex",
    gap: "6.5px",
    maxHeight: "18px",
    marginTop: "10.79px",
    alignItems: "center",
  },
  statusText: {
    color: "#56CF66",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Noto Sans",
  },
  head: {
    fontWeight: "300",
    fontFamily: "Noto sans",
    color: "#1D252B",
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    whiteSpace: "pre",
    "@media (max-width: 789px)": {
      fontSize: "14px",
    },
  },
  content: {
    fontWeight: "400",
    fontFamily: "Noto sans",
    color: "#1D252B",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  quickStyle: {
    color: "#1D252B",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 300,
    marginBottom: "13px",
    "@media (max-width: 599px)": {
      display: "none",
    },
  },
};

export default PolicyDetailsHeaderStyles;
