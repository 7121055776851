import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getFeatures,
  postFeatures,
} from "../../../store/admin/features/featuresActions";
import { RootState } from "../../../store/store";
import { IFeature } from "../../../models/admin/features/features";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";

const Features = () => {
  const dispatch = useAppDispatch();

  const { features } = useAppSelector((state: RootState) => state.features);
  const { calledReducerType, isError, isSuccess } = useAppSelector(
    (state: RootState) => state.common
  );

  const [expanded, setExpanded] = useState<string | false>(false);
  const [allFeatures, setAllFeatures] = useState<{
    [key: string]: IFeature[];
  }>({});
  const [selectedFeatures, setSelectedFeatures] = useState<IFeature[]>([]);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const handleChange =
    (parentCode: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (
        parentCode === "" ||
        selectedFeatures.some((feature) => feature.featureCode === parentCode)
      ) {
        setExpanded(isExpanded ? parentCode : false);
      } else {
        setExpanded(false);
      }
    };

  const getChildFeatures = useCallback(
    (parentCode: string) => {
      return features.filter((feature) => feature.parentCode === parentCode);
    },
    [features]
  );

  const handleCheckBoxChange = useCallback(
    (childFeature: IFeature) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          selectedFeatures.some(
            (selectedFeature) =>
              childFeature.featureCode === selectedFeature.featureCode
          )
        ) {
          // If the feature is already selected, remove it and its children
          const removeFeatureWithChildren = (
            feature: IFeature,
            featuresList: IFeature[]
          ) => {
            // Find all child features recursively
            const children = selectedFeatures.filter(
              (f) => f.parentCode === feature.featureCode
            );
            let updatedList = featuresList.filter(
              (f) => f.featureCode !== feature.featureCode
            );

            // Recursively remove children
            children.forEach((child) => {
              updatedList = removeFeatureWithChildren(child, updatedList);
            });

            return updatedList;
          };

          setSelectedFeatures((prevState) =>
            removeFeatureWithChildren(childFeature, prevState)
          );
        } else {
          // If the feature is not selected, add it to the selected features
          setSelectedFeatures((prevState) => [
            ...prevState,
            { ...childFeature, selected: true },
          ]);
        }
      },
    [selectedFeatures]
  );

  const handleSave = useCallback(() => {
    if (selectedFeatures.length > 0) {
      dispatch(
        postFeatures(
          features.map((feature) => ({
            ...feature,
            selected: selectedFeatures.some(
              (selectedFeature) =>
                selectedFeature.featureCode === feature.featureCode
            ),
          }))
        )
      );
    } else {
      alert("Please select atleast one feature");
    }
  }, [selectedFeatures, features, dispatch]);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    dispatch(getFeatures());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFeatures());
  }, [dispatch]);

  useEffect(() => {
    if (features.length > 0) {
      const allFeatures: { [key: string]: IFeature[] } = {};
      features.forEach((feature) => {
        if (allFeatures[feature.parentCode]) {
          allFeatures[feature.parentCode].push(feature);
        } else {
          allFeatures[feature.parentCode] = [feature];
        }
      });
      setAllFeatures(allFeatures);

      const selectedFeatures = features.filter((feature) => feature.selected);
      setSelectedFeatures(selectedFeatures);
    }
  }, [features, getChildFeatures]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "features/postFeatures"
    ) {
      setMessageDialogOpen(true);
    }
  }, [isError, isSuccess, calledReducerType]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Typography variant="h4">Features</Typography>
        {Object.entries(allFeatures).map(([parentCode, childFeatures]) => {
          if (
            selectedFeatures.some(
              (selectedFeature) => selectedFeature.featureCode === parentCode
            ) ||
            parentCode === ""
          ) {
            return (
              <Accordion
                key={parentCode}
                expanded={expanded === parentCode}
                onChange={handleChange(parentCode)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Feature Name
                  </Typography>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {parentCode === ""
                      ? "LOB"
                      : features.find(
                          (feature) => feature.featureCode === parentCode
                        )?.featureLabel}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {childFeatures.map((childFeature) => (
                    <FormControlLabel
                      key={childFeature.featureCode}
                      control={
                        <Checkbox
                          checked={selectedFeatures.some(
                            (feature) =>
                              feature.featureCode === childFeature.featureCode
                          )}
                          onChange={handleCheckBoxChange(childFeature)}
                        />
                      }
                      label={childFeature.featureLabel}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            width: "max-content",
          }}
        >
          Save
        </Button>
      </Box>
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
    </>
  );
};

export default Features;
