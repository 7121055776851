import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import WhiteButton from "../../common/WhiteButton";
import BasicInfoCardQuickActionsStyles from "./BasicInfoCardQuickActionsStyles";
import ContactButtonComponent from "../QuickActions/ContactButtonComponent";
import AutoPaymentComponent from "../QuickActions/AutoPaymentComponent";
import EllipsisButtonComponent from "../QuickActions/EllipsisButtonComponent";
import FileAClaim from "../../../../components/FileAClaim/FileAClaim";

export const buttonText = [
  "File a claim",
  "Contact",
  "Pay",
  "Download ID",
  "More",
];

interface IProps {
  handleDownloadIdCard: () => void;
  handlePayment: () => void;
}

const BasicInfoCardQuickActions: React.FC<IProps> = ({
  handleDownloadIdCard,
  handlePayment,
}) => {
  const isMobile = useMediaQuery("(max-width:899px)");
  const isTablet = useMediaQuery("(min-width:900px) and (max-width:1300px)");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [fileAClaimOpen, setFileAClaimOpen] = useState<boolean>(false);

  const buttonsToDisplay = isMobile ? 1 : isTablet ? 4 : buttonText.length;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string
  ) => {
    const target = event.currentTarget;
    if (text === "File a claim") {
      setFileAClaimOpen(true);
      setAnchorEl(target);
      setSelectedAction(text);
    }
    if (text === "Download ID") {
      // handleDownloadIdCard();
    }
    if (text === "Pay") {
      handlePayment();
    } else {
      setAnchorEl(target);
      setSelectedAction(text);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAction("");
  };

  const renderPopoverContent = () => {
    if (selectedAction === "File a claim") {
      return <FileAClaim open={fileAClaimOpen} setOpen={setFileAClaimOpen} />;
    }
    if (selectedAction === "Contact") {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ContactButtonComponent />
        </Popover>
      );
    }
    if (selectedAction === "Enable Auto Pay") {
      return <AutoPaymentComponent onClose={handleClose} open={open} />;
    }
    if (selectedAction === "More") {
      return (
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={handleClose}
            sx={{
              color: "#1D252B",
              fontFamily: "Noto Sans",
              fontStyle: "normal",
              fontWeight: 300,
            }}
          >
            Road Side Assistance
          </MenuItem>
        </Menu>
      );
    }
    return null;
  };

  useEffect(() => {
    if (isMobile || isTablet) {
      setAnchorEl(null);
      setSelectedAction("");
    }
  }, [isMobile, isTablet]);

  return (
    <Box
      sx={BasicInfoCardQuickActionsStyles.BasicInfoQuickActionContainer}
      aria-label="Quick Actions Container"
    >
      {isMobile ? (
        <Box sx={BasicInfoCardQuickActionsStyles.container}>
          <Box sx={BasicInfoCardQuickActionsStyles.buttonsContainer}>
            {buttonText.slice(0, buttonsToDisplay).map((text, index) => (
              <WhiteButton
                key={index}
                buttonText={text}
                buttonStyles={BasicInfoCardQuickActionsStyles.buttonStyle}
                onClick={(
                  event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => handleClick(event, text)}
              />
            ))}
          </Box>
          <EllipsisButtonComponent
            options={[
              "Contact",
              "Pay",
              "Download ID",
              "Road Side Assistance",
              "Policy Change",
              "Cancel Policy",
            ]}
            handleOptionClick={handleClick}
          />
          {renderPopoverContent()}
        </Box>
      ) : (
        <>
          {isTablet ? (
            <>
              <Box
                aria-label="tablet container"
                sx={BasicInfoCardQuickActionsStyles.BasicInfoQuickActionCard}
              >
                <Box sx={BasicInfoCardQuickActionsStyles.buttonsContainer}>
                  {buttonText.slice(0, buttonsToDisplay).map((text, index) => (
                    <WhiteButton
                      key={index}
                      buttonText={text}
                      buttonStyles={BasicInfoCardQuickActionsStyles.buttonStyle}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleClick(event, text)}
                    />
                  ))}
                </Box>
                <EllipsisButtonComponent
                  handleOptionClick={handleClick}
                  options={[
                    "Road Side Assistance",
                    "Policy Change",
                    "Cancel Policy",
                  ]}
                />
                {renderPopoverContent()}
              </Box>
            </>
          ) : (
            <>
              <Typography sx={BasicInfoCardQuickActionsStyles.quickStyle}>
                {"Quick Actions"}
              </Typography>
              <Box
                sx={BasicInfoCardQuickActionsStyles.BasicInfoQuickActionCard}
              >
                {buttonText.map((text, index) => (
                  <WhiteButton
                    key={index}
                    buttonText={text}
                    buttonStyles={BasicInfoCardQuickActionsStyles.buttonStyle}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleClick(event, text)}
                  />
                ))}
                {renderPopoverContent()}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default BasicInfoCardQuickActions;
