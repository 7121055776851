import { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import HeaderStyles from "./HeaderStyles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { deleteCookie } from "../../utils/Utility";
import MenuIcon from "@mui/icons-material/Menu";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getUiSettings } from "../../store/admin/uiSettings/uiSettingsActions";
import { logout } from "../../store/user/userAuth/userAuthActions";
import { RootState } from "../../store/store";

const Header = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { uiSettings } = useAppSelector((state: RootState) => state.uiSettings);
  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { isSuccess, isError, calledReducerType, message } = useAppSelector(
    (state: RootState) => state.common
  );

  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = useCallback(() => {
    navigate("/customer/profile");
    setAnchorEl(null);
  }, [navigate]);

  const handleMyTasksClick = () => {
    navigate("/customer/tasks");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (userDetails && userDetails.customerId) {
      setAnchorEl(null);
      dispatch(logout(userDetails.customerId));
    } else {
      alert("Please try again...");
    }
  };

  useEffect(() => {
    if (!uiSettings) {
      dispatch(getUiSettings());
    }
  }, [uiSettings, dispatch]);

  return (
    <>
      <Box sx={HeaderStyles.headerContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            img: {
              height: "50%",
              cursor: "pointer",
            },
          }}
        >
          {uiSettings?.logo ? (
            <img
              src={`data:image/png;base64,${uiSettings.logo}`}
              alt="smart-ims logo"
              onClick={() => navigate("/customer/dashboard")}
            />
          ) : (
            <></>
          )}
        </Box>
        <MenuIcon sx={{ cursor: "pointer" }} onClick={handleMenuClick} />
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock
      >
        <MenuItem onClick={handleProfileClick}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <AccountCircleIcon />
            <Typography>Profile</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleMyTasksClick}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <AssignmentRoundedIcon />
            <Typography>My Tasks</Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <LogoutIcon />
            <Typography>Logout</Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
});

export default Header;
