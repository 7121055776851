import { useEffect, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Error from "./common/Error/Error";
import Login from "./Login/Login";
import CustomerRoutes from "./customers/CustomerRoutes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { RootState } from "./store/store";
import { getCookie } from "./utils/Utility";
import Registration from "./registration/Registration";
import { setUserDetails } from "./store/user/userAuth/userAuthSlice";
import AdminRoutes from "./admin/AdminRoutes";
import { setAdminDetails } from "./store/admin/adminAuth/adminAuthSlice";
import { adminPages, customerPages } from "./constants/Constants";

const ApplicationRoutes = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const authenticated = getCookie("authenticated");
  const id = getCookie("id");
  const name = getCookie("name");
  const roleCode = getCookie("roleCode");

  const { isLoading } = useAppSelector((state: RootState) => state.common);
  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { adminDetails } = useAppSelector(
    (state: RootState) => state.adminAuth
  );

  const routeToAdmin = useCallback(() => {
    const pathArray = location.pathname.split("/");
    if (pathArray[1] !== "admin") {
      navigate("/admin/dashboard");
    } else {
      if (
        pathArray[2] &&
        adminPages
          .filter((pageName) => pageName !== "login")
          .some((page: string) => page === pathArray[2])
      ) {
        navigate(location.pathname);
      } else {
        navigate("/admin/dashboard");
      }
    }
  }, [navigate, location.pathname]);

  const routeToCustomer = useCallback(() => {
    const pathArray = location.pathname.split("/");
    if (pathArray[1] !== "customer") {
      navigate("/customer/dashboard");
    } else {
      if (
        pathArray[2] &&
        customerPages.some((page: string) => page === pathArray[2])
      ) {
        navigate(location.pathname);
      } else {
        navigate("/customer/dashboard");
      }
    }
  }, [navigate, location.pathname]);

  const checkAuthentication = useCallback(() => {
    switch (roleCode) {
      case "1":
        if (!adminDetails) {
          dispatch(setAdminDetails({ id, name }));
        }
        routeToAdmin();
        return;
      case "2":
        if (!userDetails) {
          dispatch(setUserDetails({ customerId: id, customerName: name }));
        }
        routeToCustomer();
        return;
    }
  }, [
    roleCode,
    routeToAdmin,
    routeToCustomer,
    dispatch,
    id,
    name,
    userDetails,
    adminDetails,
  ]);

  useEffect(() => {
    if (authenticated) {
      checkAuthentication();
    }
  }, [authenticated, checkAuthentication]);

  return (
    <>
      <Dialog
        open={isLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        <CircularProgress size="4rem" />
      </Dialog>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/customer/*" element={<CustomerRoutes />} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="*" element={<Error header={true} {...props} />} />
      </Routes>
    </>
  );
};

export default ApplicationRoutes;
