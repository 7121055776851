import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { MenuItem, Select, Tabs, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PolicyTabStyles } from "./PolicyTabStyles";
import Activity from "./Activity/Activity";
import Billing from "./Billing/Billing";
import { policyTabsLists } from "../../../constants/Constants";
import Claims from "./Claims/Claims";
import LienHolders from "./LienHolders/LienHolders";
import Coverages from "./Coverages/Coverages";
import Documents from "./Documents/Documents";
import VehiclesAndDrivers from "./VehiclesAnDrivers/VehiclesAndDrivers";

const PolicyTab = () => {
  const [value, setValue] = useState<number>(0);
  const [selected, setSelected] = useState<string>("0");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabComponents: { [key: string]: React.ComponentType<any> } = {
    "0": Activity,
    "1": Coverages,
    "2": VehiclesAndDrivers,
    "3": Documents,
    "4": LienHolders,
    "5": Billing,
    "6": Claims,
  };

  const getComponent = (index: string) => {
    const ComponentItem = tabComponents[index.toString()];
    return ComponentItem ? (
      <ComponentItem />
    ) : (
      <Box>Missing component for this tab</Box>
    );
  };

  const isMobile = useMediaQuery("(max-width:789px)");

  return (
    <Box sx={PolicyTabStyles.PolicyTabsStyles}>
      {isMobile ? (
        <Box sx={{ padding: "0px" }}>
          <Select
            variant="standard"
            labelId="label"
            id="select"
            value={selected}
            IconComponent={KeyboardArrowDownIcon}
            onChange={(event) => setSelected(event.target.value as string)}
            sx={PolicyTabStyles.selectStyles}
          >
            {policyTabsLists.map((tabName, index) => (
              <MenuItem
                key={index}
                value={index.toString()}
                sx={PolicyTabStyles.menuStyles}
              >
                {tabName}
              </MenuItem>
            ))}
          </Select>
          {selected && <Box>{getComponent(selected)}</Box>}
        </Box>
      ) : (
        <Box>
          <Tabs
            sx={PolicyTabStyles.tabList}
            onChange={handleChange}
            aria-label="Tab List"
            value={value}
          >
            {policyTabsLists.map((tabName, index) => (
              <Tab
                key={index}
                label={tabName}
                sx={PolicyTabStyles.tab}
                value={index}
              />
            ))}
          </Tabs>
          {getComponent(value.toString())}
        </Box>
      )}
    </Box>
  );
};

export default PolicyTab;
