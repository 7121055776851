import { useCallback, useEffect, useState } from "react";
import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import {
  SETTINGS_AND_PREFERENCES,
  NOTIFICATIONS,
  LET_US_KNOW,
  defaultDuration,
} from "../../../constants/Constants";
import { Checkbox } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CustomTable, { IColumn } from "../../../common/CustomTable/CustomTable";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getPreferenceTypes,
  getUserPreferences,
  postUserPreferences,
  resetUserPreferences,
} from "../../../store/user/profile/profileActions";
import { IUserPreference } from "../../../models/user/Profile/Profile";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CloseOutlined, ErrorOutlineOutlined } from "@mui/icons-material";
import { ILookupValue } from "../../../models/common/common";

const SettingsAndPreferences = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state) => state.userAuth);
  const { preferenceTypes, channels, durations, userSelectedPreferences } =
    useAppSelector((state) => state.profile);
  const { isSuccess, calledReducerType, message, isError } = useAppSelector(
    (state) => state.common
  );

  const [userPreferences, setUserPreferences] = useState<
    IUserPreference[] | []
  >([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [resetPreferencesAlertDialopOpen, setResetPreferencesAlertDialogOpen] =
    useState<boolean>(false);

  const getUserPreferenceChannelCheckedValue = useCallback(
    (channelCode: string, typeCode: string): boolean => {
      return userPreferences.some(
        (userPreference) =>
          userPreference.preferenceChannel === channelCode &&
          userPreference.preferenceType === typeCode
      );
    },
    [userPreferences]
  );

  const getUserPreferenceChannelDuartionValue = useCallback(
    (channelCode: string, typeCode: string): string => {
      const durationValue = userPreferences.find(
        (userPreference) =>
          userPreference.preferenceChannel === channelCode &&
          userPreference.preferenceType === typeCode
      )?.duration;
      if (durationValue) {
        return durationValue;
      }
      return "";
    },

    [userPreferences]
  );

  const handleCheckboxChange = useCallback(
    (channelCode: string, typeCode: string) => {
      setUserPreferences((prevState) => {
        const index = prevState.findIndex(
          (userPreference) =>
            userPreference.preferenceChannel === channelCode &&
            userPreference.preferenceType === typeCode
        );
        if (index > -1) {
          return prevState.filter(
            (userPreference) =>
              !(
                userPreference.preferenceChannel === channelCode &&
                userPreference.preferenceType === typeCode
              )
          );
        } else {
          return [
            ...prevState,
            {
              customerId: userDetails?.customerId || "",
              duration: defaultDuration,
              preferenceChannel: channelCode,
              preferenceType: typeCode,
              status: "ACTIVE",
            },
          ];
        }
      });
    },
    [userDetails?.customerId]
  );

  const handleDurationChange = useCallback(
    (event: SelectChangeEvent, channelCode: string, typeCode: string) => {
      const value = event.target.value as string;
      setUserPreferences((prevState) =>
        prevState.map((preference) =>
          preference.preferenceChannel === channelCode &&
          preference.preferenceType === typeCode
            ? { ...preference, duration: value }
            : preference
        )
      );
    },
    []
  );

  const columns: IColumn[] = [
    { label: "Scenarios", accessorKey: "label" },
    ...channels.map((channel: ILookupValue) => ({
      label: channel.label,
      accessorKey: "",
      Cell: (row: any) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={getUserPreferenceChannelCheckedValue(
                channel.code,
                row.code
              )}
              onChange={() => handleCheckboxChange(channel.code, row.code)}
            />
            <FormControl fullWidth>
              <InputLabel>Duration</InputLabel>
              <Select
                value={getUserPreferenceChannelDuartionValue(
                  channel.code,
                  row.code
                )}
                label="Duration"
                onChange={(event: SelectChangeEvent) =>
                  handleDurationChange(event, channel.code, row.code)
                }
                disabled={
                  !userPreferences.some(
                    (userPreference) =>
                      userPreference.preferenceChannel === channel.code &&
                      userPreference.preferenceType === row.code
                  )
                }
              >
                {durations.map((duartion: ILookupValue) => (
                  <MenuItem key={duartion.id} value={duartion.code}>
                    {duartion.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        );
      },
    })),
  ];

  const handleSavePreferences = useCallback(() => {
    dispatch(postUserPreferences(userPreferences));
  }, [dispatch, userPreferences]);

  const handleResetPreferences = useCallback(() => {
    if (userDetails) {
      dispatch(resetUserPreferences(userDetails?.customerId));
    }
  }, [dispatch, userDetails]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
    if (userDetails && userDetails.customerId) {
      dispatch(getUserPreferences(userDetails.customerId));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    dispatch(getPreferenceTypes());
  }, [dispatch]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.customerId &&
      isSuccess &&
      calledReducerType === "profile/getPreferenceTypes"
    ) {
      dispatch(getUserPreferences(userDetails?.customerId));
    }
  }, [userDetails, isSuccess, calledReducerType, dispatch]);

  useEffect(() => {
    if (
      ((isSuccess || isError) &&
        calledReducerType === "profile/postUserPreferences") ||
      ((isSuccess || isError) &&
        calledReducerType === "profile/resetUserPreferences")
    ) {
      setResetPreferencesAlertDialogOpen(false);
      setDialogOpen(true);
    }
  }, [isSuccess, calledReducerType, isError]);

  useEffect(() => {
    if (isSuccess && calledReducerType === "profile/getUserPreferences") {
      setUserPreferences(userSelectedPreferences);
    }
  }, [isSuccess, calledReducerType, userSelectedPreferences]);

  return (
    <>
      <Box sx={{ display: "grid", gap: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Playfair Display",
            fontSize: "18px",
            fontWeight: "400",
            borderBottom: " 1px solid rgba(208, 209, 217, 1)",
            "@media(max-width:599px)": {
              fontSize: "20px",
              fontWeight: "500",
            },
          }}
        >
          {SETTINGS_AND_PREFERENCES}
        </Typography>
        <Box>
          <Typography
            sx={{
              fontFamily: "Noto Sans",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {NOTIFICATIONS}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "@media(max-width:699px)": {
                flexDirection: "column",
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Noto Sans",
                fontSize: "14px",
                fontWeight: "300",
              }}
            >
              {LET_US_KNOW}
            </Typography>
          </Box>
        </Box>
        <CustomTable columns={columns} data={preferenceTypes} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSavePreferences}
            disabled={userPreferences.length === 0}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={() => setResetPreferencesAlertDialogOpen(true)}
            disabled={userSelectedPreferences.length === 0}
          >
            Reset To Default
          </Button>
        </Box>
      </Box>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={handleDialogClose}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {isSuccess &&
          (calledReducerType === "profile/postUserPreferences" ||
            calledReducerType === "profile/resetUserPreferences") ? (
            <CheckCircleOutlineIcon sx={{ fontSize: "60px", color: "green" }} />
          ) : isError &&
            (calledReducerType === "post/postUserPreferences" ||
              calledReducerType === "profile/resetUserPreferences") ? (
            <ErrorOutlineOutlined sx={{ fontSize: "60px", color: "red" }} />
          ) : (
            <></>
          )}
          <DialogContentText color={isError ? "red" : ""}>
            {message ? message : "Something went wrong please try again"}
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <Dialog open={resetPreferencesAlertDialopOpen}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent dividers>
          Are you sure to reset your preferences to default settings?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setResetPreferencesAlertDialogOpen(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            onClick={handleResetPreferences}
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettingsAndPreferences;
