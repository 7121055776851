import { CSSProperties, ReactElement, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomTableStyles from "./CustomTableStyles";
import { TablePagination, Typography } from "@mui/material";
import { rowsPerPageOptions } from "../../constants/Constants";

export interface IColumn {
  label: string;
  accessorKey?: string;
  styles?: CSSProperties;
  Cell?: <T>(item: T) => void;
}

interface IProps {
  columns: IColumn[];
  data: any[];
  pagination?: boolean;
  styles?: CSSProperties;
  stickyHeader?: boolean;
}

const CustomTable = (props: IProps) => {
  const { columns, data, pagination, styles, stickyHeader } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      pagination
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : data,
    [page, rowsPerPage, data, pagination]
  );

  const getTableHeader = (): ReactElement[] => {
    return columns.map((column: IColumn, index: number) => (
      <TableCell
        key={`cust-header-${column.accessorKey}-${index}`}
        sx={{
          ...CustomTableStyles.tableHeadCell,
          ...column.styles,
        }}
      >
        {column.label}
      </TableCell>
    ));
  };

  const getTableBody = () => {
    let tableBody: any = (
      <TableRow sx={CustomTableStyles.row}>
        <TableCell sx={CustomTableStyles.rowCell}>
          <Typography>No records found</Typography>
        </TableCell>
      </TableRow>
    );
    if (data && data.length > 0) {
      tableBody = visibleRows.map((item: any, index: number) => {
        return (
          <TableRow
            key={`cust-tab-body-${index}`}
            sx={{
              ...CustomTableStyles.row,
              borderBottom:
                index === visibleRows.length - 1
                  ? "transparent"
                  : "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            {columns.map((column: IColumn, index: number) => {
              return (
                <TableCell
                  sx={{
                    ...CustomTableStyles.rowCell,
                    width: `${100 / columns.length}%`,
                  }}
                  key={`cust-tab-td-${index}`}
                >
                  {column.Cell
                    ? column.Cell(item)
                    : column.accessorKey
                    ? item[column.accessorKey]
                    : ""}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
    }
    return tableBody;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ ...CustomTableStyles.tableContainer, ...styles }}>
        <Table stickyHeader={stickyHeader}>
          <TableHead sx={{ borderBottom: "2px solid rgba(63, 63, 63, 1)" }}>
            <TableRow>{getTableHeader()}</TableRow>
          </TableHead>
          <TableBody>{getTableBody()}</TableBody>
        </Table>
      </TableContainer>
      {pagination && data && data.length > 5 ? (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default CustomTable;
