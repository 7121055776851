import {
  Card,
  Box,
  Typography,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import DriversStyles from "./DriversStyles";
import { IPolicyDriver } from "../../../../../models/user/policyDetails/PolicyDetails";
import CustomTable from "../../../../../common/CustomTable/CustomTable";
import { useCallback, useEffect, useState } from "react";
import AddDriverForm from "./AddDriverForm/AddDriverForm";
import {
  DeleteOutline,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../../store/store";
import { postRemoveDriver } from "../../../../../store/user/policyChange/policyChangeActions";
import { formatDateToYYYYMMDD } from "../../../../../utils/Utility";
import AlertDialog from "../../../../../components/AlertDialog/AlertDialog";
import MessageDialog from "../../../../../components/MessageDialog/MessageDialog";
import CustomIcon from "../../../../../common/CustomIcon/CustomIcon";

interface IProps {
  drivers: IPolicyDriver[];
}

function DriverTable({ drivers }: IProps) {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const isMobile = useMediaQuery("(max-width:600px)");

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { policyDetailsHeader } = useAppSelector(
    (state: RootState) => state.policyDetails
  );
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state) => state.common
  );

  const [addDriverFormOpen, setAddDriverFormOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<IPolicyDriver | null>(
    null
  );
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleAddDriverClick = useCallback(() => {
    setAddDriverFormOpen(true);
  }, []);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    driver: IPolicyDriver
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDriver(driver);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteDriverClick = () => {
    handleMenuClose();
    setAlertDialogOpen(true);
  };

  const handleAlertDialogCancelClick = () => {
    setAlertDialogOpen(false);
  };

  const handleAlertDialogOkClick = () => {
    setAlertDialogOpen(false);
    if (
      selectedDriver &&
      policyId &&
      userDetails &&
      userDetails.customerId &&
      policyDetailsHeader &&
      policyDetailsHeader.carrierPolicyNumber
    ) {
      dispatch(
        postRemoveDriver({
          carrierPolicyNo: policyDetailsHeader.carrierPolicyNumber,
          changeDt: formatDateToYYYYMMDD(new Date()),
          customerId: userDetails.customerId,
          detailDescription: "Remove driver from the policy",
          driverInfo: {
            driverAge: selectedDriver.driverAge.toString(),
            dateOfBirth: selectedDriver.dateOfBirth,
            genderCd: selectedDriver.gender,
            givenName: selectedDriver.driverName,
            licensedStateProvCd: selectedDriver.licensedStateProvCd,
            licenseDt: selectedDriver.licenseDt,
            licenseNumber: selectedDriver.drivingLicense,
            maritalStatus: selectedDriver.maritalStatus,
            otherGivenName: selectedDriver.otherGivenName,
            relationshipToInsuredCd: selectedDriver.relationshipToInsuredCd,
            surName: selectedDriver.driverSurName,
          },
          policyRef: policyId,
        })
      );
    }
  };

  const handleMessageModalClose = useCallback(() => {
    setMessageDialogOpen(false);
  }, []);

  const columns = [
    {
      label: "Name",
      accessorKey: "givenName",
      Cell: (row: any) => {
        return `${row.driverName} ${row.driverSurName}`;
      },
    },
    {
      label: "License",
      accessorKey: "licenseNumber",
      Cell: (row: any) => {
        return `${
          row.licensedStateProvCd ? `${row.licensedStateProvCd}-` : ""
        }${row.drivingLicense}`;
      },
    },
    {
      label: "Type",
      accessorKey: "typeOfDriver",
    },
    {
      label: "Gender",
      accessorKey: "gender",
    },
    {
      label: "Age",
      accessorKey: "driverAge",
    },
    {
      label: "Marital Status",
      accessorKey: "maritalStatus",
    },
    {
      label: "",
      accessorKey: "",
      Cell: (driver: any) => (
        <IconButton onClick={(event) => handleMoreClick(event, driver)}>
          <MoreVertOutlined sx={{ cursor: "pointer" }} />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "policyChange/postRemoveDriver"
    ) {
      setMessageDialogOpen(true);
    }
  }, [isSuccess, isError, calledReducerType]);

  return (
    <>
      <Box sx={{ marginTop: "20px", display: "grid", gap: "20px" }}>
        <Box sx={DriversStyles.headingAndButton}>
          <Box sx={DriversStyles.vehicleHeading}>
            <CustomIcon iconName="Driver" />
            {/* <img
              src={DriverLogo}
              alt="driver logo"
              style={{ width: "40px", height: "40px" }}
            /> */}
            <Typography sx={DriversStyles.HeadingText}>
              {`Drivers (${drivers.length})`}
            </Typography>
          </Box>
          {policyDetailsHeader?.carrierPolicyStatus === "Cancelled" ? (
            ""
          ) : (
            <Button
              variant="contained"
              sx={DriversStyles.addVehicleButton}
              onClick={handleAddDriverClick}
            >
              Add Driver
            </Button>
          )}
        </Box>
        {isMobile ? (
          drivers.length > 0 ? (
            drivers.map((driver: IPolicyDriver, index: number) => (
              <Card key={`dri-${index}`} sx={DriversStyles.mobileCard}>
                <Box sx={DriversStyles.driverName}>
                  <Typography sx={DriversStyles.name}>{`${driver.driverName} ${
                    driver.driverSurName || ""
                  }`}</Typography>
                  <Typography sx={DriversStyles.gender}>
                    {driver.gender} | {driver.driverAge}
                  </Typography>
                </Box>
                <Typography sx={DriversStyles.drivingLicense}>
                  {driver.licensedStateProvCd}-{driver.drivingLicense}
                </Typography>
                <Box>
                  <Box sx={DriversStyles.type}>
                    Type:&nbsp;
                    <Typography sx={DriversStyles.vehicleType}>
                      {driver.typeOfDriver}
                    </Typography>
                  </Box>
                </Box>
                <IconButton
                  sx={{ position: "absolute", right: "0px" }}
                  onClick={(event) => handleMoreClick(event, driver)}
                >
                  <MoreVertOutlined />
                </IconButton>
              </Card>
            ))
          ) : (
            <Card sx={DriversStyles.noDriversCard}>
              <Typography sx={DriversStyles.noDriversText}>
                No Drivers Found
              </Typography>
            </Card>
          )
        ) : (
          <CustomTable columns={columns} data={drivers} />
        )}
      </Box>
      <AddDriverForm open={addDriverFormOpen} setOpen={setAddDriverFormOpen} />
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleMenuClose}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <EditOutlined sx={{ fontSize: "16px" }} />
            <Typography>Edit</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          onClick={handleDeleteDriverClick}
          disabled={drivers.length === 1}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <DeleteOutline sx={{ fontSize: "16px" }} />
            <Typography>Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <AlertDialog
        handleCancelClick={handleAlertDialogCancelClick}
        handleOkClick={handleAlertDialogOkClick}
        open={alertDialogOpen}
        title="Deleting this vehicle will end its coverage under this policy"
      />
      <MessageDialog
        handleClose={handleMessageModalClose}
        open={messageDialogOpen}
      />
    </>
  );
}

export default DriverTable;
