import {
  setIsLoading,
  setIsSuccess,
  setIsError,
  setMessage,
  setDisplayMsg,
  setCalledReducerType,
  resetCommonSlice,
} from "./common/CommonSlice";

let ongoingRequests = 0;

export const CustomAction = async (
  apiMethod: any,
  dispatch: any,
  reducerType: string | null = null,
  displayMsg: boolean = false,
  customMsg: string | null = null
) => {
  // Reset the common slice to clear previous states

  dispatch(resetCommonSlice());

  // Increment ongoing requests counter
  ongoingRequests += 1;

  if (ongoingRequests >= 1) {
    dispatch(setIsLoading(true));
  }

  if (reducerType) {
    dispatch(setCalledReducerType(reducerType));
  }

  try {
    const { data } = await apiMethod;
    if (
      data.status === "SUCCESS" ||
      (data.byteLength && data.byteLength > 59)
    ) {
      dispatch(setIsSuccess(true));
    } else {
      dispatch(setIsError(true));
    }
    dispatch(setMessage(customMsg || data.message));
    if (displayMsg) {
      dispatch(setDisplayMsg(true));
    }
    return data;
  } catch (error) {
    dispatch(setIsError(true));
    throw error;
  } finally {
    // Decrement ongoing requests counter
    ongoingRequests -= 1;

    if (ongoingRequests === 0) {
      dispatch(setIsLoading(false));
    }
  }
};
