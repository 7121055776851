const InsureAssistStyles = {
  drawerPaper: {
    width: "40%",
    "@media (max-width:992px)": {
      width: "60%",
    },
    "@media (max-width:600px)": {
      width: "100%",
    },
  },
  container: {
    width: "100%",
    height: "100vh",
    display: "grid",
    gridTemplateRows: "10% 73% 17%",
    "@media (max-width:600px)": {
      gridTemplateRows: "10% 75% 15%",
    },
  },
  header: {
    padding: "20px",
    textAlign: "center",
    background: "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    display: "flex",
    alignItems: "center",
  },
  headerTitle: {
    margin: "auto",
  },
  closeButton: {
    ":hover": { cursor: "pointer" },
  },
  messagesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    flexGrow: 1,
  },
  messageBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  messageContent: {
    maxWidth: "80%",
    minWidth: "20%",
    padding: "10px",
    "--tw-bg-opacity": 1,
    background: "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    borderRadius: "10px",
    wordBreak: "break-word",
    display: "grid",
    gap: "5px",
  },
  messageSender: {
    fontSize: "10px",
    color: "#1D252B",
    fontWeight: 300,
    fontFamily: "Noto Sans",
  },
  messageContentBox: {
    padding: "0px 20px",
  },
  messageTimestamp: {
    fontSize: "10px",
    color: "#1D252B",
    fontWeight: 300,
    fontFamily: "Noto Sans",
    textAlign: "right",
  },
  loadingBox: {
    width: "80%",
  },
  footer: {
    padding: "6px 10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  clearChatButton: {
    padding: "6px",
    fontSize: "10px",
  },
  formControl: {
    width: "100%",
  },
  micIconButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "50%",
    width: "48px",
    height: "48px",
    animation: "none",
  },
  sendIconButton: {
    color: "action.active",
    ":hover": { cursor: "pointer" },
  },
  blinkAnimation: `
    @keyframes blink-animation {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
      }
      70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
      }
      100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
      }
    }
  `,
};

export default InsureAssistStyles;
