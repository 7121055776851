import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Card } from "@mui/material";
import {
  IDocument,
  IDocumentContentRequest,
  IDocumentsRequestParams,
} from "../../../../models/user/policyDetails/Document";
import { DocumentsStyles } from "./DocumentsStyles";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import Document from "./Document/Document";
import { getBufferData } from "../../../../utils/Utility";
import { GetInsuranceDocumentContent } from "../../../../store/user/policyDetails/policyDetailsActions";
import { setInsuranceDocumentContent } from "../../../../store/user/policyDetails/policyDetailsSlice";

const Documents: React.FC = () => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { insuranceDocuments, insuranceDocumentContent } = useAppSelector(
    (store: RootState) => store.policyDetails
  );

  const { isSuccess, calledReducerType, isError } = useAppSelector(
    (store: RootState) => store.common
  );
  const [actionType, setActionType] = useState<string | null>(null);
  const [currentClickFile, setCurrentClickFile] = useState<IDocument | null>(
    null
  );

  const handleFileClick = (type: string, documentData: IDocument) => {
    if (!policyId) {
      return;
    }
    setActionType(type);
    setCurrentClickFile(documentData);
    const requestParams: IDocumentContentRequest = {
      policyId: policyId,
      documentId: documentData?.documentId,
    };
    dispatch(GetInsuranceDocumentContent(requestParams));
  };

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetPolicyDocumentContent" &&
      insuranceDocumentContent &&
      actionType
    ) {
      const bufferArray = getBufferData(insuranceDocumentContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      if (actionType === "view") {
        window.open(url, "_blank");
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.download = `${currentClickFile?.name}-${currentClickFile?.addDate}-${currentClickFile?.addTime}.pdf`;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      dispatch(setInsuranceDocumentContent(null));
    }
  }, [
    calledReducerType,
    isSuccess,
    insuranceDocumentContent,
    actionType,
    currentClickFile,
    dispatch,
  ]);

  useEffect(() => {
    if (
      isError &&
      calledReducerType === "GetPolicyDocumentContent" &&
      actionType
    ) {
      alert(`Error in ${actionType} document`);
    }
  }, [calledReducerType, isError, actionType]);

  // useEffect(() => {
  //   if (policyId) {
  //     const requestParams: IDocumentsRequestParams = {
  //       policyId,
  //     };
  //     dispatch(GetInsuranceDocuments(requestParams));
  //   }
  // }, [policyId, dispatch]);

  return (
    <>
      {(!insuranceDocuments || insuranceDocuments.length <= 0) && (
        <Card sx={DocumentsStyles.card}>
          <Box sx={DocumentsStyles.cardContentBox}>No documents found</Box>
        </Card>
      )}
      {insuranceDocuments &&
        insuranceDocuments.length > 0 &&
        insuranceDocuments?.map((document: IDocument, index: number) => (
          <Document
            key={`doc-list-${index}`}
            documentData={document}
            handleFileClick={handleFileClick}
            view={true}
          />
        ))}
    </>
  );
};

export default Documents;
