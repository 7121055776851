const EllipsisButtonStyles = {
  text: {
    fontWeight: 300,
    fontFamily: "Noto Sans",
    fontSize: "16px",
  },
  popOver: {
    "& .MuiPopover-paper": {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      padding: "16px ",
    },
  },
  box: {
    width: "188px",
  },
  iconButton: {
    padding: "0px",
  },
};

export default EllipsisButtonStyles;
