import { useAppSelector } from "../../../../../store/hooks";
import { Box, Chip, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomIcon from "../../../../../common/CustomIcon/CustomIcon";

const Discounts = () => {
  const { coveragesAndDiscounts } = useAppSelector(
    (state) => state.policyDetails
  );

  return coveragesAndDiscounts?.discounts ? (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <CustomIcon iconName="Discount" />
        <Typography
          sx={{
            fontFamily: "Playfair Display",
            fontSize: "20px",
            fontHeight: "400",
            lineHeight: "22px",
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          Discounts
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: "30px",
          backgroundColor: "white",
          borderRadius: "10px",
          border: "1px solid rgba(201, 225, 236, 1)",
        }}
      >
        {coveragesAndDiscounts.discounts.map((discout) => (
          <Chip
            label={discout.discountName}
            variant="filled"
            icon={<CheckCircleIcon sx={{ color: "black !important" }} />}
            sx={{
              backgroundColor: "#ffbb67",
              color: "black",
              margin: "0.3rem",
            }}
          />
        ))}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Discounts;
