import { FC, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import paymentStyle from "./PaymentStyle";
import { currencyFormat } from "../../utils/Utility";

interface IProps {
  amount: string;
  carrierPolicyNumber: string;
}

const Payment: FC<IProps> = (props) => {
  const { amount, carrierPolicyNumber } = props;
  const [amountValue, setAmountValue] = useState<string>(amount);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const regex = /^\d{0,5}(\.\d{0,2})?$/;

    if (regex.test(value) || value === "") {
      setAmountValue(value);
    }
  };

  const getTotalAmount = () => {
    const totalAmount: number = +parseFloat(amountValue || "0").toFixed(2);
    return totalAmount * 100;
  };

  return (
    <form
      style={{ width: "100%" }}
      method="post"
      action={process.env.REACT_APP_PAYEMENT_URL}
    >
      <input type="hidden" name="carrierPolicyNo" value={carrierPolicyNumber} />
      <input type="hidden" name="totalAmount" value={getTotalAmount()} />
      <Box sx={paymentStyle.mainBox}>
        <Box sx={paymentStyle.fieldSec}>
          <Box sx={paymentStyle.fieldLabel}>
            <Typography> Amount: </Typography>
          </Box>
          <Box sx={paymentStyle.amountValue}>
            <TextField
              name="amount"
              type="text"
              value={amountValue}
              onChange={handleChange}
              inputProps={{
                inputMode: "decimal",
                pattern: "\\d{0,5}(\\.\\d{0,2})?",
              }}
            />
          </Box>
        </Box>
        <Box sx={paymentStyle.textAmountSection}>
          <Box sx={paymentStyle.amountLabelBox}>
            <Typography sx={paymentStyle.amountLabel}>Due Amount:</Typography>
          </Box>
          <Box sx={paymentStyle.amountAmtBox}>
            <Typography sx={paymentStyle.amt}>
              {currencyFormat("us", +parseFloat(amountValue || "0").toFixed(2))}
            </Typography>
          </Box>
        </Box>
        <Box sx={paymentStyle.paySection}>
          <Button type="submit" name="pay" sx={paymentStyle.payBtn}>
            Pay Now
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Payment;
