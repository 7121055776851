import { useCallback, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import PolicyDetails from "./PolicyDetails/PolicyDetails";
import PaymentSuccess from "./Payment/PaymentSuccess";
import PaymentCancel from "./Payment/PaymentCancel";
import Tasks from "./Tasks/Tasks";
import Profile from "./Profile/Profile";
import { Box, Fab } from "@mui/material";
import InsureAssist from "./Dashboard/InsureAssist/InsureAssist";
import ChatIcon from "@mui/icons-material/Chat";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { getCookie } from "../utils/Utility";

const CustomerRoutes = () => {
  const navigate = useNavigate();

  const authenticated = getCookie("authenticated");
  const roleCode = getCookie("roleCode");

  const [insureAssistOpen, setInsureAssistOpen] = useState<boolean>(false);

  const toggleChatDrawer = useCallback((): void => {
    setInsureAssistOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!authenticated || roleCode !== "2") {
      window.location.href = "/";
    }
  }, [authenticated, roleCode, navigate]);

  return authenticated && roleCode === "2" ? (
    <Box
      sx={{
        background: "linear-gradient(180deg, #fbfdfc 0%, #e5f6fe 100%)",
        position: "relative",
      }}
    >
      <Header />
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/policyDetails/:policyId" element={<PolicyDetails />} />
        <Route
          path="/paymentSuccess/:responseData"
          element={<PaymentSuccess />}
        />
        <Route path="/paymentError/:errorCode" element={<PaymentCancel />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Dashboard />} />
      </Routes>
      <Footer />

      <Fab
        sx={{
          position: "fixed",
          bottom: 70,
          right: 16,
          fontFamily: "Noto Sans",
          backgroundColor: "rgba(63, 63, 63, 1)",
          boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
          ":hover": { backgroundColor: "#000000" },
        }}
        onClick={toggleChatDrawer}
      >
        <ChatIcon sx={{ color: "white", fontSize: "30px" }} />
      </Fab>
      <InsureAssist open={insureAssistOpen} setOpen={setInsureAssistOpen} />
    </Box>
  ) : (
    <></>
  );
};

export default CustomerRoutes;
