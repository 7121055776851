import { createSlice } from "@reduxjs/toolkit";
import { IClaimsSlice } from "../../../models/user/claims/claims";
import { getFileAClaimFormFieldsJson } from "./claimsActions";

const initialState: IClaimsSlice = {
  fileAClaimFormFieldsJson: null,
  fileAClaimResponse: null,
};

const claimsSlice = createSlice({
  name: "claims",
  initialState: initialState,
  reducers: {
    setFileAClaimFormFieldsJson: (state, { payload }) => {
      state.fileAClaimFormFieldsJson = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFileAClaimFormFieldsJson.fulfilled,
      (state, { payload }) => {
        state.fileAClaimFormFieldsJson = payload.data;
      }
    );
  },
});

export default claimsSlice.reducer;
export const { setFileAClaimFormFieldsJson } = claimsSlice.actions;
