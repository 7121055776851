import { Box, Card, Typography } from "@mui/material";
import { useState } from "react";
import PoliciesStyles from "./PoliciesStyles";
import { IPolicy } from "../../../models/user/dashboard/Dashboard";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import PolicyCard from "./PolicyCard/PolicyCard";
import rightArrowIcon from "../../../assets/viewAllArrow.svg";
import CustomDialog from "../../../common/CustomDialog/CustomDialog";

interface IProps {
  handleDownloadIdCard: (policyId: string) => void;
}

const Policies: React.FC<IProps> = (props) => {
  const { handleDownloadIdCard } = props;
  const { policiesData } = useAppSelector(
    (store: RootState) => store.dashboard
  );

  const [viewAllPoliciesDialogOpen, setViewAllPolicesDialogOpen] =
    useState(false);

  const handleViewAllClick = () => {
    setViewAllPolicesDialogOpen(true);
  };

  const handleAllPoliciesModalClose = () => {
    setViewAllPolicesDialogOpen(false);
  };

  const getPolicies = () => {
    if (policiesData && policiesData.policyList) {
      let policies = [...policiesData.policyList];
      let sortedPolicies = policies
        .sort((a, b) => {
          if (
            a.carrierPolicyStatus.toLowerCase() <
            b.carrierPolicyStatus.toLowerCase()
          ) {
            return -1;
          }
          if (
            a.carrierPolicyStatus.toLowerCase() >
            b.carrierPolicyStatus.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => {
          if (a.lineCode.toLowerCase() < b.lineCode.toLowerCase()) {
            return -1;
          }
          if (a.lineCode.toLowerCase() > b.lineCode.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      if (sortedPolicies.length > 0) {
        return sortedPolicies;
      } else {
        return policiesData.policyList;
      }
    } else {
      return [];
    }
  };

  return (
    <>
      <Box sx={PoliciesStyles.policiesContainer}>
        <Box sx={PoliciesStyles.policiesContainerHeader}>
          <Typography sx={PoliciesStyles.heading}>
            Here is your list of policies
          </Typography>
          {policiesData &&
          policiesData.policyList &&
          policiesData.policyList.length > 2 ? (
            <Box sx={PoliciesStyles.viewAll} onClick={handleViewAllClick}>
              <Typography sx={PoliciesStyles.viweAllText}>View All</Typography>
              <img src={rightArrowIcon} alt="right-arrow-icon" />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {getPolicies().length > 0 ? (
          <Box sx={PoliciesStyles.policies}>
            {getPolicies()
              .slice(0, 2)
              .map((policy: IPolicy, index: number) => (
                <PolicyCard
                  policy={policy}
                  key={policy.policyId}
                  handleDownloadIdCard={handleDownloadIdCard}
                />
              ))}
          </Box>
        ) : (
          <Card sx={PoliciesStyles.noPoliciesCard}>
            <Typography sx={PoliciesStyles.noPoliciesText}>
              No Policies Available
            </Typography>
          </Card>
        )}
      </Box>
      <CustomDialog
        showDivider={true}
        open={viewAllPoliciesDialogOpen}
        maxWidth="xl"
        onClose={handleAllPoliciesModalClose}
        title="All Policies"
        content={
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              "@media(max-width: 992px)": {
                gridTemplateColumns: "1fr",
              },
            }}
          >
            {getPolicies().map((policy: IPolicy, index: number) => (
              <PolicyCard
                policy={policy}
                key={policy.policyId}
                handleDownloadIdCard={handleDownloadIdCard}
              />
            ))}
          </Box>
        }
      />
    </>
  );
};

export default Policies;
