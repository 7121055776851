import myClaimsIcon from "../assets/myClaimsIcon.svg";
import carIcon from "../assets/car.svg";
import roadsideAssistanceIcon from "../assets/roadsideAssistanceIcon.svg";
import paymentHistoryIcon from "../assets/svgs/paymentHistory.svg";
import starIcon from "../assets/star.svg";
import learnAboutIcon from "../assets/svgs/learnAboutInsuranceIcon.svg";
import viewAllArrow from "../assets/viewAllArrow.svg";
import leftArrow from "../assets/left_arrow.svg";
import filter from "../assets/filter.svg";
import searchIcon from "../assets/search.svg";
import updownArrow from "../assets/upDownArrow.svg";
import closeImage from "../assets/x-circle.svg";
import ellipsis from "../assets/vertical_ellipse.svg";
import downArrow from "../assets/downArrow.svg";
import activeClaim from "../assets/activeclaims.svg";
import pastClaim from "../assets/pastClaims.svg";
import carSmall from "../assets/carSmall.svg";

export const SETTINGS_AND_PREFERENCES = "Settings and Preferences";
export const LANGUAGE = "Language";
export const SELECT_LANGUAGE = "Select Language you are comfortable with";
export const NOTIFICATIONS = "Notifications";
export const LET_US_KNOW =
  "Let us know how and when you would like to be notified";
export const PROFILE_DETAILS_HEADING = "Profile Details";

export const customerPages = [
  "policyDetails",
  "dashboard",
  "paymentSuccess",
  "paymentError",
  "profile",
  "tasks",
];
export const adminPages = [
  "dashboard",
  "forms",
  "lookup",
  "uisettings",
  "features",
  "login",
];
export const policiesHeading = "Here Is Your List Of Policies";
export const quickReferences = "Quick References For You";
export const footerLeftText = "© 2024 Smart IMS. All rights reserved.";
export const termsAndService = "Terms of Service";
export const privatePolicy = "Privacy Policy";
export const viewAll = "View All";
export const trackYourClaim = "Track Your Claim";
export const renewalAmount = "Renewal Amount :";
export const renewNow = "Renew Now";
export const duesDate = "Due Date :";
export const terms = " : Tax and Fee may apply.";
export const newClaim = "New Claim";
export const paymentHistory = "Payment History";
export const roadSideAssistance = "Roadside Assistance";
export const learnAbout = "Learn about Insurance";
export const name = "Mathew";
export const viewPast = "View Past Claims";
export const attentionText = "Alerts";
export const breadcrumbText = "12AW1145G057 |  Auto_Garage101";
export const leftArrowText = "Navigate Before Icon";
export const policyDetailsHead = "Policy";
export const vehicleName = "Elena Rodriguez";
export const numberOfVEchiles = "2";
export const billingHead = "Invoice";
export const price = "821.00";
export const dueDate = "01 Mar’24";
export const premiumHead = "Premium";
export const annaulPrice = "2917.00";
export const term = "1 Year";
export const vaild = "02 Mar‘23 - 01 Mar’24";
export const status = "Active";
export const dueOn = "Due on";
export const fileClaim = "File a claim";
export const download = "Download";
export const addWallet = "Add to wallet";
export const fileAClaim = "File a Claim";
export const clickToUpload = "Click to upload";
export const claimSubmitted = "Claim Submitted";
export const referenceNumber = "Please find your claim reference number ";
export const contactAgent = "Contact your Agent for further process";
export const agentDetails = "Agent Details";
export const businessAddress = "Business Address";
export const loginMessage = "Welcome, please login to continue";
export const loginName = "Mathew Thomas";
export const loginPassword = "Mathew Thomas";
export const signedIn = "Keep me signed in.";
export const forgotPassword = "Forgot password?";
export const login = "Login";
export const register = "Register here";
export const account = "Don't have an account?";
export const residential = "Residential Address";
export const contact = "Contact Details";
export const selectPayment = "Select Payment Method";
export const creditCard = "Credit Card";
export const debitCard = "Debit Card";
export const paymentmethod = "Payment Method";
export const cancel = "Cancel";
export const save = "Save";
export const activeClaims = "Active Claims";
export const pastClaims = "Past Claims";
export const turnOnAutoPayment = "Turn-on Auto Payment";
export const changePassword = "Change Password";
export const createNew = "Hi, kindly create a new password.";
export const setNewPassword =
  "Your new password must be different to your previously three used passwords";
export const passwordMustContain = "Password must contain";
export const currentPassword = "Current Password";
export const confirmPassword = "Confirm Password";
export const newPassword = "New Password";
export const atleastEight = "At least 8 characters.";
export const oneUpperCase = "At least 1 UPPERCASE letter (A-Z)";
export const oneLowerCase = "At least 1 lowercase letter (a-z)";
export const oneNumber = "At least 1 number (0-9)";
export const complete = "Complete";
export const incidentType = "Incident Type";
export const editProfile = "Edit Profile";
export const enterNewPassword = "Forget Password";
export const phoneNumber = "Phone Number";
export const city = "City";
export const state = "State";
export const country = "Country";
export const policyTypeLabel = "Policy Type";
export const policyHash = "Policy #";
export const fileAClaimVehicle = "Vehicle";
export const incidentLocation = "Incident Location";
export const incidentDescription = "Incident Description";
export const names = "Name";
export const contactNumber = "Contact Number";
export const bankName = "Bank Name";
export const accountNumber = "Account Number";
export const address = "Address";
export const enableAll = "Enable All";
export const language = "Language";

export const policyNumber = "Policy Number";
export const policyStatus = "Status";
export const policyPremium = "Premium:";
export const policyTerm = "Term:";
export const policyDueDate = "Due Date:";
export const days = "Days";
export const daysIn = "in";
export const password = "Password";
export const payoutAmount = "Payout Amount:";
export const claimNumber = "Claim Number:";
export const closureReason = "Closure Reason:";
export const filterText = "Filter";
export const billingAmount = " Billing amount";
export const billingDate = "Billing Date";
export const paidAmount = "Paid Amount";
export const paidOn = "Paid on";
export const coveragesLimits = "Coverage and Limits";
export const discounts = "Discounts";
export const coverages = "Coverages";
export const limits = "Limit";
export const typ = "Type";

export const MYCLAIM_ICON = myClaimsIcon;
export const CAR_ICON = carIcon;
export const STAR_ICON = starIcon;
export const PAYMENT_ICON = paymentHistoryIcon;
export const ASSISTANCE_ICON = roadsideAssistanceIcon;
export const LEARN_ICON = learnAboutIcon;
export const VIEW_ARROW = viewAllArrow;
export const LEFT_ARROW = leftArrow;
export const FILTER = filter;
export const SEARCH = searchIcon;
export const UP_DOWN_ARROW = updownArrow;
export const ELLIPSIS = ellipsis;
export const CLOSE = closeImage;
export const DOWN_ARROW = downArrow;
export const ACTIVE_CLAIM = activeClaim;
export const PAST_CLAIM = pastClaim;
export const CAR_ICON_SMALL = carSmall;
export const vehicles = "Vehicles";
export const drivers = "Drivers";
export const vehicle = "Vehicles";
export const driver = "Drivers";
export const imageNotFound = "image not found";
export const policyTabsLists = [
  "Activity",
  "Coverages",
  "Vehicles & Drivers",
  "Documents",
  "Lienholder",
  "Billing",
  "Claims",
];

export const youtubeVideoUrl = "https://www.youtube.com/embed/JFjOpajKeOw";
export const videoUrl =
  "http://3.20.231.65/videos/Navigating%20Auto%20Liability%20Limits%20Your%20Road%20to%20Financial%20Security.mp4";

export const dateFormat = "MMM DD, YYYY";

export const countryCurrency = {
  us: {
    name: "en-US",
    currency: "USD",
  },
};

export const rowsPerPageOptions = [5, 10, 25];
export const defaultDuration = "7";

//** Admin */
export const lookupFormFields = [
  { name: "label", label: "Label" },
  { name: "name", label: "Name" },
  { name: "code", label: "Code" },
];
export const defaultUiSetting = {
  iconColor: "rgb(255 192 112)",
};
