import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { getCookie } from "../utils/Utility";
import { logout } from "../store/user/userAuth/userAuthActions";
import { AppDispatch } from "../store/store";

// Define the expected type for the error response data
interface ErrorResponseData {
  message: string;
  // add other properties as needed
}

const createApiInstance = (dispatch: AppDispatch): AxiosInstance => {
  const Api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  // Request interceptor
  Api.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor
  Api.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        const responseData = error.response.data as ErrorResponseData;
        if (responseData.message) {
          alert(responseData.message);
        } else {
          alert("Session expired...");
        }
        const userId = getCookie("id");
        dispatch(logout(userId));
        dispatch(() => ({ type: "logout" }));
        window.location.href = "/";
      }
      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
    }
  );

  return Api;
};

export default createApiInstance;
