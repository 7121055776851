import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./app-theme/theme";
import ApplicationRoutes from "./Routes";
import "./index.css";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from "react-pdf";
import createApiInstance from "./Apis/AxiosInterceptor";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Api = createApiInstance(store.dispatch);

// Make Api available globally (if needed)
export { Api };

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ApplicationRoutes />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
