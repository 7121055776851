import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Popover, Typography } from "@mui/material";
import EllipsisButtonStyles from "./EllipsisButtonStyles";
import { ELLIPSIS } from "../../../../constants/Constants";

interface EllipsisButtonProps {
  handleOptionClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string
  ) => void;
  options: string[];
}

const EllipsisButtonComponent: React.FC<EllipsisButtonProps> = ({
  handleOptionClick,
  options,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const buttonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    option: string
  ) => {
    handleOptionClick(event, option);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        aria-label="more Icon"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={EllipsisButtonStyles.iconButton}
      >
        <img src={ELLIPSIS} alt="vertical ellipsis icon" />
      </IconButton>
      <Popover
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        aria-labelledby="long-button"
        sx={EllipsisButtonStyles.popOver}
      >
        {options.map((option) => (
          <Box key={option} sx={EllipsisButtonStyles.box}>
            <Typography
              onClick={(event: any) => buttonClick(event, option)}
              sx={EllipsisButtonStyles.text}
            >
              {option}
            </Typography>
          </Box>
        ))}
      </Popover>
    </>
  );
};

export default EllipsisButtonComponent;
