import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Link } from "@mui/material";
import PolicyDetailsStyles from "./PolicyDetailsStyles";
import PolicyTab from "./policyTabComponents/PolicyTab";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { IGetPolicyDetailsHeaderRequestParams } from "../../models/user/policyDetails/PolicyDetails";
import { GetDownloadIdCard } from "../Dashboard/DashboardAction";
import { IDownloadIdCardParams } from "../../models/user/dashboard/Dashboard";
import { getBufferData } from "../../utils/Utility";
import Payment from "../Payment/Payment";
import { setDownloadIdCardContent } from "../Dashboard/DashboardSlice";
import CustomDialog from "../../common/CustomDialog/CustomDialog";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import PolicyDetailsHeader from "./PolicyDetailsHeader/PolicyDetailsHeader";
import { GetPolicyDetailsHeader } from "../../store/user/policyDetails/policyDetailsActions";

const PolicyDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { policyDetailsHeader } = useAppSelector(
    (store: RootState) => store.policyDetails
  );
  let dueAmount = "0.00";
  let carrierPolicyNumber = "No Policy";
  if (policyDetailsHeader && policyDetailsHeader.dueAmount) {
    dueAmount = policyDetailsHeader.dueAmount;
  }
  if (policyDetailsHeader && policyDetailsHeader.carrierPolicyNumber) {
    carrierPolicyNumber = policyDetailsHeader.carrierPolicyNumber;
  }

  const { downloadIdCardContent } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  const { isSuccess, calledReducerType, isError } = useAppSelector(
    (store: RootState) => store.common
  );
  const [openPaymentForm, setOpenPaymentForm] = useState<boolean>(false);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetDownloadIdCard" &&
      downloadIdCardContent
    ) {
      const bufferArray = getBufferData(downloadIdCardContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      const downloadLink = document.createElement("a");
      downloadLink.download = "Insured ID card.pdf";
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch(setDownloadIdCardContent(null));
    }
  }, [calledReducerType, isSuccess, downloadIdCardContent, dispatch]);

  useEffect(() => {
    if (isError && calledReducerType === "GetDownloadIdCard") {
      alert(`Error in downloading document`);
    }
  }, [calledReducerType, isError]);

  useEffect(() => {
    if (policyId) {
      const requestParams: IGetPolicyDetailsHeaderRequestParams = {
        policyId: policyId,
      };
      dispatch(GetPolicyDetailsHeader(requestParams));
    }
  }, [policyId, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDownloadIdCard = () => {
    if (!policyId) {
      return;
    }
    const requestParams: IDownloadIdCardParams = {
      policyId,
    };
    dispatch(GetDownloadIdCard(requestParams));
  };

  const handlePayment = () => setOpenPaymentForm(true);

  const handlePaymentDialogeClose = () => setOpenPaymentForm(false);

  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate("/customer/dashBoard");
  }, [navigate]);

  return (
    <>
      <Box sx={PolicyDetailsStyles.policyContainer}>
        <Link
          onClick={() => {
            handleNavigate();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#1D252B",
            fontFamily: "Noto Sans",
            fontSize: "18px",
            fontWeight: 400,
            cursor: "pointer",
            "@media (max-width: 599px)": {
              fontSize: "14px",
            },
            "@media (min-width: 600px) and (max-width: 1024px)": {
              fontSize: "16px",
            },
            "@media (min-width: 1024px) and (max-width: 1920px)": {
              fontSize: "18px",
            },
          }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
          {policyDetailsHeader
            ? `${policyDetailsHeader.carrierPolicyNumber} | ${
                policyDetailsHeader.insuredName
              } ${policyDetailsHeader?.insuredNameJoint ? "|" : ""} ${
                policyDetailsHeader.insuredNameJoint
                  ? policyDetailsHeader.insuredNameJoint
                  : ""
              }`
            : ""}
        </Link>
        <PolicyDetailsHeader
          data={policyDetailsHeader}
          handleDownloadIdCard={handleDownloadIdCard}
          handlePayment={handlePayment}
        />
        <PolicyTab />
      </Box>
      <CustomDialog
        open={openPaymentForm}
        maxWidth="lg"
        onClose={handlePaymentDialogeClose}
        title={`Payment for Policy Number : ${carrierPolicyNumber}`}
        content={
          <Payment
            amount={dueAmount}
            carrierPolicyNumber={carrierPolicyNumber}
          />
        }
        showDivider={true}
      />
    </>
  );
};

export default PolicyDetails;
